import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import cancelPreorder from '../../api/cancelPreorder';
import checkPreOrderStatus from '../../api/checkPreOrderStatus';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import CustomButton from '../../components/button/custom-button.component';

const PreorderCancel = ({ currentUser }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const queryParams = new URLSearchParams(window.location.search);
  const stripeSessionId = queryParams.get('stripe_checkout_session_id');
  const userEmail = queryParams.get('user_email');
  const saleOrderNumber = queryParams.get('sales_order_number');
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [loading, setButtonLoading] = useState(false);
  useEffect(() => {
    if (!stripeSessionId) {
      navigate('/404');
    }
  }, []);
  const handleCancelOrder = async () => {
    setButtonLoading(true);
    const res = await cancelPreorder(stripeSessionId, userEmail);
    if (res.status === 200) {
      const retry = (fn, retriesLeft = 30, interval = 200) => {
        return new Promise((resolve, reject) => {
          fn()
            .then((response) => {
              if (!response || response.zoho_sales_order_status === 'draft') {
                setTimeout(() => {
                  if (retriesLeft === 1) {
                    console.log('maximum retries exceeded');
                    toast({
                      title: 'Order cancellation is in process',
                      description: response.message,
                      status: 'info',
                      position: 'top',
                      duration: 5000,
                      isClosable: true,
                    });
                    setButtonLoading(false);
                    return;
                  }
                  retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
              } else {
                if (response.zoho_sales_order_status === 'cancelled') {
                  setCancelSuccess(true);
                  setButtonLoading(false);
                } else {
                  toast({
                    title: 'Failed to cancel order',
                    description: 'Please contact us for support',
                    status: 'error',
                    position: 'top',
                    duration: 10000,
                    isClosable: true,
                  });
                  setButtonLoading(false);
                }
                resolve();
              }
            })
            .catch((error) => {
              setTimeout(() => {
                if (retriesLeft === 1) {
                  // maximum retries exceeded
                  reject(error);
                  return;
                }
                retry(fn, retriesLeft - 1, interval).then(resolve, reject);
              }, interval);
            });
        });
      };

      retry(() =>
        checkPreOrderStatus(
          stripeSessionId,
          currentUser && currentUser.isLoggedIn
        )
      );
    } else {
      toast({
        title: 'Failed to cancel order',
        description: res.Message,
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      setButtonLoading(false);
    }
  };
  return (
    <div className="status-page">
      <div className="status-page--content">
        {cancelSuccess ? (
          <div className="status-page-detail">
            <h1>CANCEL ORDER SUCCESSFUL</h1>
            <h3>
              Your pre-order #{saleOrderNumber} has been cancelled successfully.
            </h3>
            <div className="status-page-button-group">
              <CustomButton onClick={() => navigate('/')} variant="red">
                Go to Homepage
              </CustomButton>
            </div>
          </div>
        ) : (
          <div className="status-page-detail">
            <h1>CANCEL ORDER</h1>
            <h3>Your EdgePi pre-order number: {saleOrderNumber}</h3>
            <h3>Are you sure you want to cancel this pre-order?</h3>

            <div className="status-page-button-group">
              <CustomButton onClick={() => navigate('/')} variant="white">
                No. Keep Pre-Order
              </CustomButton>
              <CustomButton
                isLoading={loading}
                onClick={() => handleCancelOrder()}
                variant="red"
              >
                Yes. Cancel Pre-Order
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

export default connect(mapStateToProps, null)(PreorderCancel);
