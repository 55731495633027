import React, { useState, useEffect } from 'react';
import {
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Image,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  AiOutlineCheckCircle,
  AiOutlineCloudServer,
  AiOutlineCloudSync,
} from 'react-icons/ai';
import { VscCircuitBoard } from 'react-icons/vsc';
import { Card } from './Card';
import { ActionButton } from './ActionButton';
import Price from '../price/price.component';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { setCurrentUser } from '../../redux/user/user.actions';
import CloudAnimation from '../animation/cloud';
import verifyRecaptcha from '../../api/verifyRecaptcha';
import subscriptionCheckOut from '../../api/stripe/subscriptionCheckOut';
import useWindowSize from '../../api/useWindowSize';

const API_ENDPOINT = process.env.REACT_APP_STORE_API;

const PricingCard = (props) => {
  const [priceData, setPriceData] = useState({});
  const { data, setCurrentUser, currentUser, ...rest } = props;
  const { features, name } = data;
  const accentColor = useColorModeValue('#dc2f5c', '#dc2f5c');
  const size = useWindowSize();
  useEffect(() => {
    const fetchProductPricesData = async () => {
      const response = await fetch(`${API_ENDPOINT}/allprices/${data.id}`);
      const json = await response.json();
      setPriceData(json.body.data[0]);
    };

    fetchProductPricesData();
  }, []);
  const quickCheckout = async (priceId, productId) => {
    const recaptcha = await verifyRecaptcha();
    if (!recaptcha) {
      console.log('verifying reCaptcha ... Failed!');
      return false; // Stop execute checkout if failed reCaptcha check
    }
    console.log('verifying reCaptcha ... OK!');
    const quickBuyThisProduct = {};
    const priceIDs = [];
    const temObj = {};
    temObj.price = priceId;
    temObj.quantity = 1;
    priceIDs.push(temObj);
    quickBuyThisProduct.subscription_product = productId;
    quickBuyThisProduct.priceIDs = priceIDs;
    subscriptionCheckOut(currentUser, quickBuyThisProduct);

    return null;
  };
  return (
    <>
      {
        // Mobile & Tablet view
        size.width < 1250 && (
          <Card
            rounded={{
              sm: 'xl',
            }}
            {...rest}
          >
            {Object.keys(priceData).length !== 0 && priceData.recurring ? (
              <>
                <VStack spacing={6}>
                  <Image
                    boxSize="100px"
                    objectFit="contain"
                    src={data.thumbnail}
                    alt="product-thumb"
                  />
                  <h2 className="plan-name" size="lg" fontWeight="medium">
                    {name}
                  </h2>
                </VStack>
                <Flex
                  align="flex-end"
                  justify="center"
                  fontWeight="extrabold"
                  color={accentColor}
                  my="8"
                >
                  <Heading
                    size="xl"
                    color="#ddaa4a"
                    fontWeight="medium"
                    lineHeight="0.9em"
                  >
                    <Price
                      currency={priceData.currency}
                      amount={priceData.unit_amount}
                      interval={priceData.recurring.interval}
                    />
                  </Heading>
                </Flex>
                <List spacing="4" mb="8" maxW="28ch" mx="auto">
                  <ListItem className="plan-features" fontWeight="medium">
                    <ListIcon
                      fontSize="xl"
                      as={AiOutlineCheckCircle}
                      marginEnd={2}
                      color={accentColor}
                    />
                    Status / Config: <span>{features['Cloud Config']}</span>
                  </ListItem>
                  <ListItem className="plan-features" fontWeight="medium">
                    <ListIcon
                      fontSize="xl"
                      as={AiOutlineCloudSync}
                      marginEnd={2}
                      color={accentColor}
                    />
                    Data Traffic / Month:{' '}
                    <span>{features['Data Traffic / Month']}</span>
                  </ListItem>
                  <ListItem className="plan-features" fontWeight="medium">
                    <ListIcon
                      fontSize="xl"
                      as={AiOutlineCloudServer}
                      marginEnd={2}
                      color={accentColor}
                    />
                    Data Storage: <span>{features['Data Storage']}</span>
                  </ListItem>
                  <ListItem className="plan-features" fontWeight="medium">
                    <ListIcon
                      fontSize="xl"
                      as={VscCircuitBoard}
                      marginEnd={2}
                      color={accentColor}
                    />
                    Max # devices: <span>{features['Max # devices']}</span>
                  </ListItem>
                </List>
                {name !== 'Free' ? (
                  <ActionButton
                    onClick={() => quickCheckout(priceData.id, data.id)}
                    isDisabled={!process.env.REACT_APP_SUBSCRIPTION_ACTIVE}
                  >
                    Subscribe
                  </ActionButton>
                ) : (
                  <ActionButton isDisabled>Subscribe</ActionButton>
                )}
              </>
            ) : (
              <CloudAnimation />
            )}
          </Card>
        )
      }

      {
        // Desktop view
        size.width >= 1250 && (
          <Card className="desktop-plan-header" view="desktop" {...rest}>
            {Object.keys(priceData).length !== 0 && priceData.recurring ? (
              <>
                <Flex mt={6} className="plan-header-container">
                  <Image
                    boxSize="40px"
                    objectFit="contain"
                    src="/edgepi.svg"
                    alt="product-thumb"
                    mr={2}
                  />
                  <h2 className="plan-name">{name}</h2>
                </Flex>
                <Flex
                  justify="start"
                  fontWeight="extrabold"
                  color={accentColor}
                  my="8"
                >
                  <Heading
                    size="xl"
                    color="#ddaa4a"
                    fontWeight="medium"
                    lineHeight="0.9em"
                    className="desktop-plan-price"
                  >
                    <Price
                      currency={priceData.currency}
                      amount={priceData.unit_amount}
                      interval={priceData.recurring.interval}
                    />
                  </Heading>
                </Flex>

                {name !== 'Free' ? (
                  <ActionButton
                    onClick={() => quickCheckout(priceData.id, data.id)}
                    isDisabled={!process.env.REACT_APP_SUBSCRIPTION_ACTIVE}
                  >
                    Subscribe
                  </ActionButton>
                ) : (
                  <ActionButton isDisabled>Subscribe</ActionButton>
                )}
              </>
            ) : (
              <CloudAnimation />
            )}
          </Card>
        )
      }
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingCard);
