import checkAuthentication from '../checkAuthentication';

const STORE_API_ENDPOINT = process.env.REACT_APP_STORE_API;

const createStripeCustomer = async (
  userEmail, // for logged user : cognito email , for new guest user: input email in shipping edit page
  values
) => {
  const user = await checkAuthentication();
  const myHeaders = new Headers();
  if (user) {
    const accessToken = user.signInUserSession.accessToken.jwtToken;
    myHeaders.append('Authorization', `Bearer ${accessToken}`);
  }
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify({
      customer_email: userEmail,
      ...values,
    }),
  };

  const response = await fetch(
    `${STORE_API_ENDPOINT}/customers?user_email=${userEmail}`,
    requestOptions
  );
  const json = await response.json();
  return { status: response.status, ...json };
};

export default createStripeCustomer;
