import React from 'react';
import { Box, Container } from '@chakra-ui/react';

const ShippingReturnPolicy = () => (
  <Box w="100%" className="policy-page">
    <Container maxW={1400}>
      <h1>Shipping & Returns Policy</h1>
      <p>Our team at EdgePi wants you to be thrilled with your purchase.</p>
      <p>
        If you have any questions about our shipping policies or are
        dissatisfied in any way with your purchase and need to return it,{' '}
        <span>we are here to help</span>.
      </p>
      <hr />
      <h2>Shipping</h2>
      <h3>Shipping Policy :</h3>
      <p>
        We currently ship to Canada, USA, Australia and the EU. Standard and
        expedited options are available for shipping with rates calculated at
        checkout. Free shipping is offered with orders over $500 USD. All
        shipments have tracking numbers for you to track at your convenience.
      </p>
      <h2>Returns</h2>
      <h3>30 Day Money Back :</h3>
      <p>
        We offer a 30-day money-back return period on all products and
        accessories. A 30-day return authorization must be obtained through the
        store.edgepi.com website by logging into your account and selecting
        “Request 30-day Refund”. This option will only be available for 35 days
        after the date your order was shipped. This includes a 5-day grace
        period to allow for time in transit.
      </p>

      <h3>Standard Warranty :</h3>
      <p>
        All returns within the warranty period must be initiated through the
        store.edgepi.com website by logging into your account and selecting
        “Warranty RMA Request”.
      </p>

      <h3>Repair or Recycling :</h3>
      <p>
        For product repair outside the warranty period or product returns for
        environmental recycling, please contact support@edgepi.com.
        Troubleshooting and repair services are charged at $100/hr USD, with a
        $200 USD min charge plus any applicable return shipping charges.
      </p>

      <h3>Return Shipping Charges :</h3>
      <p>
        All 30-Day Money-Back Returns must be shipped to EdgePi in the original
        boxes in like-new condition. Standard Warranty Returns do not need the
        original packaging but must be packaged in a secure manner that would
        prevent damage. Customers are responsible for shipping charges to EdgePi
        located in Burnaby, British Columbia, Canada. EdgePi will cover the
        shipping cost back to the customer to a maximum of $50 USD. Some
        international customers may be contacted if the shipping charge exceeds
        this amount.
      </p>
    </Container>
  </Box>
);

export default ShippingReturnPolicy;
