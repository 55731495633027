import React from 'react';
import { motion } from 'framer-motion';
import CollectionItem from '../collection-item/collection-item.component';
import './collection-preview.scss';

const CollectionPreview = ({ id, status, ...otherProps }) => {
  return (
    <div
      className={`collection-preview ${
        status === 'Out of Stock' ? 'sold-out-item' : ''
      } `}
    >
      <motion.div
        className="preview"
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <CollectionItem key={id} {...otherProps} />
      </motion.div>
    </div>
  );
};

export default CollectionPreview;
