import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Container,
  HStack,
  Button,
  Icon,
  Input,
  Image,
  Flex,
  SimpleGrid,
  useNumberInput,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { addItems } from '../../redux/cart/cart.actions';
import {
  addQuickbuyItem,
  removeQuickbuyItem,
} from '../../redux/quickbuy/quickbuy.actions';
import { setCurrentSession } from '../../redux/user/user.actions';
import { selectCollections } from '../../redux/shop/shop.selectors';
import CloudAnimation from '../../components/animation/cloud';
import CollectionPreview from '../../components/collection-preview/collection-preview.component';
import CheckOutModal from '../../components/checkout-modal/checkout-modal.component';
import PreOrderModal from '../../components/preorder-modal/preorder.component';
import ImageSlider from '../../components/image-slider/image-slider.component';
import checkAuthentication from '../../api/checkAuthentication';
import verifyRecaptcha from '../../api/verifyRecaptcha';
import CustomButton from '../../components/button/custom-button.component';
import Price from '../../components/price/price.component';
import productPlaceholder from '../../assets/product_placeholder.jpeg';
import './product.styles.scss';

const API_ENDPOINT = process.env.REACT_APP_STORE_API;
let productQuantity = 1;

const HookUsage = ({ max }) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      max: max ?? 10,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps({ isReadOnly: true });

  productQuantity = input.value;

  return (
    <HStack maxW="320px">
      <Button {...dec}>-</Button>
      <Input {...input} />
      <Button {...inc}>+</Button>
    </HStack>
  );
};

const ProductPage = ({
  collections,
  addItems,
  addQuickbuyItem,
  removeQuickbuyItem,
  setCurrentSession,
}) => {
  const toast = useToast();
  const [product, setProduct] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const [currency, setCurrency] = useState('USD');
  const [salePrice, setSalePrice] = useState();
  const [regPrice, setRegPrice] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const { id } = useParams();
  const {
    isOpen: isCheckOutModalOpen,
    onOpen: onCheckOutModalOpen,
    onClose: onCheckOutModalClose,
  } = useDisclosure();
  const {
    isOpen: isPreOrderModalOpen,
    onOpen: onPreOrderModalOpen,
    onClose: onPreOrderModalClose,
  } = useDisclosure();

  const navigate = useNavigate();

  const notify = (name) =>
    toast({
      title: `${name} has been added in your cart`,
      status: 'success',
      position: 'top',
      duration: 2000,
      isClosable: true,
    });

  const onCreateAccount = () => {
    Auth.federatedSignIn({ customState: window.location.pathname });
  };

  const quickCheckout = async () => {
    setButtonLoading(true);

    const recaptcha = await verifyRecaptcha();
    if (!recaptcha) {
      console.log('verifying reCaptcha ... Failed!');
      return false; // Stop execute checkout if failed reCaptcha check
    }
    console.log('verifying reCaptcha ... OK!');
    product.quantity = productQuantity;
    removeQuickbuyItem();
    addQuickbuyItem(product);
    setCurrentSession('quickbuy');
    navigate('/shipping');

    return null;
  };

  const onOpenCheckOut = async () => {
    const result = await checkAuthentication();
    if (!result.username) {
      onCheckOutModalOpen();
    } else {
      quickCheckout();
    }
  };

  useEffect(() => {
    setSimilarProducts([]);
    setSalePrice();

    const currentProduct = collections.find((item) => item.id === id);

    // Retrieve product prices data from STRIPE API
    const fetchProductPricesData = async () => {
      const response = await fetch(`${API_ENDPOINT}/allprices/${id}`);
      if (response.status === 200) {
        const json = await response.json();
        currentProduct.prices = json.body.data;
        setPrices(json.body.data);
        setProduct(currentProduct);
        setCurrency(json.body.data[0].currency);
        currentProduct.prices.forEach((price) => {
          if (price.nickname === 'sale') {
            setSalePrice(price.unit_amount);
          } else {
            setRegPrice(price.unit_amount);
          }
        });
      }
      window.scrollTo(0, 0);
    };

    const fetchSimilarProducts = () => {
      if (
        currentProduct.metadata &&
        currentProduct.metadata['Similar Products']
      ) {
        const res = collections.filter((item) =>
          JSON.parse(currentProduct.metadata['Similar Products']).includes(
            item.id
          )
        );
        setSimilarProducts(res);
      }
    };

    if (currentProduct && Object.keys(currentProduct).length !== 0) {
      fetchSimilarProducts();
      fetchProductPricesData();
    }
  }, [collections, id]);

  return (
    <div className="single-product-page">
      <Container maxW="full" p={0}>
        <div className="main-product">
          <Flex
            alignItems="center"
            className="product-back-btn"
            onClick={() => navigate('/shop')}
          >
            <Icon as={IoArrowBackCircleOutline} w={10} h={10} mr={2} />
            <h3>Back to Store</h3>
          </Flex>
          <SimpleGrid
            columns={{ md: 1, lg: 2 }}
            maxW="6xl"
            mx="auto"
            justifyItems="center"
            alignItems="center"
            spacing={24}
          >
            {product !== {} &&
            product &&
            product.images &&
            prices.length > 0 ? (
              <>
                {product.images.length > 0 ? (
                  <ImageSlider slides={product.images} />
                ) : (
                  <Image
                    boxSize="400px"
                    objectFit="contain"
                    src={productPlaceholder}
                    alt="EdgePi Product"
                  />
                )}

                <div className="product-details">
                  <h1>{product.name}</h1>
                  {product.metadata.Model && (
                    <h5 className="product-model">{product.metadata.Model}</h5>
                  )}
                  <h5 className="product-specs">{product.description}</h5>
                  <hr />

                  <h3 className="product-price">
                    <div className="prices-wrapper">
                      {salePrice && (
                        <span className="sale-price">
                          <Price currency={currency} amount={salePrice} />
                        </span>
                      )}

                      <span
                        className={`${
                          salePrice ? 'original-price' : 'regular-price'
                        }`}
                      >
                        <Price currency={currency} amount={regPrice} />
                      </span>
                    </div>
                  </h3>

                  {/* <p className="product-description">{product.description}</p> */}
                  {product.metadata.Features && (
                    <ul className="product-features">
                      <h4>Features:</h4>
                      {JSON.parse(product.metadata.Features).map((feature) => (
                        <li key={feature}>{feature}</li>
                      ))}
                    </ul>
                  )}
                  {product.prices[0].recurring == null &&
                    product.metadata['Checkout Mode'] === 'payment_auth' && (
                      <div className="quantity-input">
                        <h4>Quantity</h4>
                        <HookUsage
                          max={product.metadata['Max Order Quantity']}
                        />
                      </div>
                    )}

                  {product.metadata.Status !== 'Out of Stock' &&
                  product.metadata.Status !== 'Unlisted' ? (
                    <div className="checkout-button-group">
                      {product.metadata['Checkout Mode'] === 'payment_auth' && (
                        <CustomButton
                          onClick={() => {
                            // Add Product

                            product.quantity = Number(productQuantity);
                            addItems(product);
                            notify(product.name);
                          }}
                          variant="white"
                        >
                          ADD TO CART
                        </CustomButton>
                      )}

                      <CustomButton
                        isLoading={buttonLoading}
                        variant={
                          product.metadata['Checkout Mode'] === 'payment_future'
                            ? 'green'
                            : 'red'
                        }
                        onClick={
                          product.metadata['Checkout Mode'] === 'payment_future'
                            ? onPreOrderModalOpen
                            : onOpenCheckOut
                        }
                      >
                        {product.metadata['Checkout Mode'] === 'payment_future'
                          ? 'PRE-ORDER NOW'
                          : 'BUY NOW'}
                      </CustomButton>
                    </div>
                  ) : (
                    <div className="checkout-button-group">
                      <CustomButton isLoading={buttonLoading} variant="black">
                        OUT OF STOCK
                      </CustomButton>
                    </div>
                  )}
                  <hr />

                  <h3>SKU : {product.metadata.SKU}</h3>
                </div>
              </>
            ) : (
              <CloudAnimation />
            )}
          </SimpleGrid>
        </div>
        {similarProducts.length > 0 && (
          <div className="related-products">
            <h1>YOU MIGHT ALSO BE INTERESTED IN</h1>

            <div className="related-products--container">
              {similarProducts
                .filter((item) => item.metadata.Status !== 'Unlisted')
                .map((item) => (
                  <CollectionPreview key={item.id} item={item} />
                ))}
            </div>
          </div>
        )}
        <CheckOutModal
          isOpen={isCheckOutModalOpen}
          onClose={onCheckOutModalClose}
          onCreateAccount={onCreateAccount}
          onCheckOut={quickCheckout}
        />
        <PreOrderModal
          isOpen={isPreOrderModalOpen}
          onClose={onPreOrderModalClose}
          onOpenCheckOut={onOpenCheckOut}
        />
      </Container>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  collections: selectCollections,
});
const mapDispatchToProps = (dispatch) => ({
  addItems: (item) => dispatch(addItems(item)),
  addQuickbuyItem: (item) => dispatch(addQuickbuyItem(item)),
  removeQuickbuyItem: () => dispatch(removeQuickbuyItem()),
  setCurrentSession: (mode) => dispatch(setCurrentSession(mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
