import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import './preorder.styles.scss';

const PreOrderModal = ({ isOpen, onClose, onOpenCheckOut }) => {
  return (
    <Modal
      className="preorder-modal"
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="preorder-modal--heading">
          EdgePi Pre-order Process
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="preorder-modal--body">
          <h3>We only need your card to save your spot in line .</h3>
          <p>
            <span>Pay Nothing Today !</span> When your EdgePi order is ready to
            ship, you will be notified with an email containing a link for you
            to complete the checkout process.
          </p>
          <p className="text-emphasize">
            Your card will only be charged if you decide to complete the order
            at that time. Your card will not be charged if you decide to cancel.
          </p>
          <p>
            Your pre-order will automatically be cancelled if you do not
            complete the transaction within 30 days of being notified.
          </p>
          <p className="mt-2">
            <span>Note:</span> EdgePi uses{' '}
            <a href="https://stripe.com/" target="_blank" rel="noreferrer">
              Stripe
            </a>{' '}
            for secure payment processing. Your credit card details are never
            seen or stored by EdgePi.
          </p>
        </ModalBody>
        <ModalFooter className="preorder-modal--footer">
          <Button
            backgroundColor="#8B8B8B"
            color="#FFF"
            _hover={{ bg: '#615f5f' }}
            width={300}
            mt={2}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            backgroundColor="#dc2f5c"
            color="#FFF"
            _hover={{ bg: '#bd214b' }}
            width={300}
            mt={2}
            onClick={() => {
              onClose();
              onOpenCheckOut();
            }}
          >
            Continue to Pre-Order
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PreOrderModal;
