import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from '@chakra-ui/react';
import { ReactComponent as ErrorIcon } from '../../assets/cloud-error.svg';
import CustomButton from '../../components/button/custom-button.component';
import './error.styles.scss';

const ErrorPage = ({ currentError }) => {
  const navigate = useNavigate();
  if (!currentError || currentError.length === 0) {
    navigate('/404');
  }
  return (
    <Container className="errorpage">
      <div className="errorpage--content">
        <div className="error-icon">
          <ErrorIcon />
        </div>
        <div className="error-detail">
          <h1>{currentError.Status}</h1>
          <h3>{currentError.message}</h3>
          <p>{currentError.Message}</p>

          <CustomButton variant="red" onClick={() => navigate('/')}>
            BACK TO HOME
          </CustomButton>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  currentError: state.error.currentError,
});

export default connect(mapStateToProps, null)(ErrorPage);
