/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Hub } from 'aws-amplify';
import { connect } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import checkAuthentication from '../../api/checkAuthentication';
import { setCurrentUser } from '../../redux/user/user.actions';
import storeFetchData from '../../api/storeFetchData';
import CloudAnimation from '../../components/animation/cloud';
import './login-success.styles.scss';

const STORE_URL = process.env.REACT_APP_STORE_URL;
const STORE_API_ENDPOINT = process.env.REACT_APP_STORE_API;
const LoginSuccess = ({ setCurrentUser }) => {
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'customOAuthState') {
        const checkCurrentUser = async () => {
          const user = await checkAuthentication();
          const cognitoEmail = user.signInUserSession.idToken.payload.email;

          const obj = {
            method: 'GET',
            url: `${STORE_API_ENDPOINT}/users/${cognitoEmail}`,
            contentType: 'application/json',
          };

          const response = await storeFetchData(obj);

          if (response.status === 200) {
            // user is found (200)

            // Find the default account
            const defaultAccount = response.user.accounts.find(
              (account) => account.default
            );

            setCurrentUser({
              stripeCustomerId: defaultAccount.stripe_customer_id,
              userEmail: cognitoEmail,
              cognitoID: user.username,
              accounts: defaultAccount,
              accountId: defaultAccount.account_id,
              isLoggedIn: true,
            });
            // Redirect to the previous page
            window.history.replaceState({}, null, `${STORE_URL}${data}`);
            window.history.go();
          } else if (response.status === 404) {
            // user is not found. check guest user
            const obj = {
              method: 'GET',
              url: `${STORE_API_ENDPOINT}/guest-users/${cognitoEmail}`,
            };

            const response = await storeFetchData(obj);
            if (response.status === 200) {
              // guest account found. go to migrating page
              navigate('/account-migration', {
                state: {
                  allowMigration: true,
                  accounts: response.accounts,
                  cognitoEmail,
                },
              });
            } else {
              // guest user is not found (404) => create account

              const obj = {
                method: 'POST',
                url: `${STORE_API_ENDPOINT}/users`,
                contentType: 'application/json',
                data: { migrate_account: false },
              };
              const res = await storeFetchData(obj);

              if (res.status === 200) {
                // get the new account information => save it to redux store
                const getUserObj = {
                  method: 'GET',
                  url: `${STORE_API_ENDPOINT}/users/${cognitoEmail}`,
                  contentType: 'application/json',
                };

                // Define retry function
                const retry = (fn, retriesLeft = 30, interval = 300) => {
                  return new Promise((resolve, reject) => {
                    fn()
                      .then((response) => {
                        if (response.status === 200) {
                          // user is found (200)
                          resolve();
                          // Find the default account
                          const defaultAccount = response.user.accounts.find(
                            (account) => account.default
                          );
                          // Save the user information to Redux Store
                          setCurrentUser({
                            stripeCustomerId: defaultAccount.stripe_customer_id,
                            userEmail: cognitoEmail,
                            cognitoID: user.username,
                            accounts: defaultAccount,
                            accountId: defaultAccount.account_id,
                            isLoggedIn: true,
                          });

                          // Redirect to the previous page
                          window.history.replaceState(
                            {},
                            null,
                            `${STORE_URL}${data}`
                          );
                          window.history.go();
                        } else {
                          // user is not found (404) => also keep retry func running
                          setTimeout(() => {
                            if (retriesLeft === 1) {
                              console.log(
                                'Cannot find user. Maximum retries exceeded'
                              );

                              toast({
                                title:
                                  'Failed to get your account information.',
                                description:
                                  'Please try again later, or contact us for support',
                                status: 'error',
                                position: 'top',
                                duration: 10000,
                                isClosable: true,
                              });

                              return;
                            }
                            retry(fn, retriesLeft - 1, interval).then(
                              resolve,
                              reject
                            );
                          }, interval);
                        }
                      })
                      .catch((error) => {
                        // failed to fetch data from api => retry
                        setTimeout(() => {
                          if (retriesLeft === 1) {
                            // maximum retries exceeded
                            reject(error);
                            console.log(
                              'Cannot fetch data from BE. Maximum retries exceeded'
                            );
                            console.error(error);

                            toast({
                              title: 'Failed to get your account information.',
                              description:
                                'Please try again later, or contact us for support',
                              status: 'error',
                              position: 'top',
                              duration: 10000,
                              isClosable: true,
                            });

                            return;
                          }
                          retry(fn, retriesLeft - 1, interval).then(
                            resolve,
                            reject
                          );
                        }, interval);
                      });
                  });
                };

                // Run retry function
                retry(() => storeFetchData(getUserObj));
              } else {
                // failed to create new account. BE error occurs (5xx)
                toast({
                  title: `Failed to get your account information.`,
                  description:
                    'Please try again later, or contact us for support',
                  status: 'error',
                  position: 'top',
                  duration: 10000,
                  isClosable: true,
                });
              }
            }
          } else {
            // BE error may occurs (5xx)
            toast({
              title: 'Failed to get user information!',
              description: response.status,
              status: 'error',
              duration: 10000,
              position: 'top',
              isClosable: true,
            });
          }
        };
        checkCurrentUser();
      }
    });
  }, []);
  return (
    <div className="login-success-page">
      <div className="login-success-page--content">
        <CloudAnimation />
        <div className="login-success-page--detail">
          <h1>LOGIN SUCCESSFUL!</h1>
          <p>You will be redirected to the previous page shortly...</p>
          <p>
            If you are not redirected in 10 seconds, please click{' '}
            <a href={`${STORE_URL}`}>here</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(null, mapDispatchToProps)(LoginSuccess);
