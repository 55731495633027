import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import HomePage from './pages/homepage/homepage.component';
import ShopPage from './pages/shop/shop.component';
import Plans from './pages/plans/plans.component';
import ProductPage from './pages/product/product.component';
import CartPage from './pages/cart/cart.component';
import ShippingPage from './pages/shipping/shipping.component';
import CheckoutProcess from './pages/checkout-process/checkout-process.component';
import ErrorPage from './pages/error/error.component';
import NotFoundPage from './pages/404/404.component';
import LoginSuccess from './pages/login-success/login-success.component';
import CheckoutStatusPage from './pages/checkout-status/checkout-status.component';
import DeveloperPage from './pages/developer/developer.component';
import PreorderCancel from './pages/order-cancel/preorder-cancel.component';
import AccountMigration from './pages/account-migration/account-migration.component';
import ShippingReturnPolicy from './pages/policies/shipping-return.component';
import WarrantyPolicy from './pages/policies/warranty.component';
import PrivacyPolicy from './pages/policies/privacy-policy.component';
import Terms from './pages/policies/terms.component';
import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import {
  retrieveProductsData,
  retrievePlansData,
} from './redux/shop/shop.actions';
import useAuthentication from './api/useAuthentication';
import useCheckNewBuild from './api/useCheckNewBuild';
import useGTM from './api/useGTM';
import getProducts from './api/getProducts';
import ErrorBoundary from './pages/ErrorBoundary';
import './App.scss';

const App = ({ retrieveProductsData, retrievePlansData }) => {
  const location = useLocation();

  useEffect(() => {
    const getStripeProductsData = async () => {
      // Retrieve products data from STRIPE API
      try {
        const productsData = await getProducts('/products');

        productsData.body.data.sort((a, b) => {
          const keyA = parseInt(a.metadata['Category Number'], 10);
          const keyB = parseInt(b.metadata['Category Number'], 10);
          if (keyA > keyB) return 1;
          if (keyA < keyB) return -1;
          return 0;
        });
        const subscription = productsData.body.data.filter(
          (item) => item.metadata.Type === 'Subscription'
        );

        const collections = productsData.body.data.filter(
          (item) => item.metadata.Status || item.metadata.Type === 'Accessories'
        );

        retrievePlansData(subscription);
        retrieveProductsData(collections);

        // Run AWS Amplify configuration when app starts
        useAuthentication();
      } catch (err) {
        console.log(err);
      }
    };

    // Retrieve products data from STRIPE API
    getStripeProductsData();

    // load the script by passing the URL
    useGTM(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`,
      function () {
        console.log('reCaptcha script loaded!');
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Check build version on route changes => force user to logout if there is a new build
    useCheckNewBuild();
  }, [location]);

  return (
    <ErrorBoundary>
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/plans" element={<Plans />} />
        <Route exact path="/product/:id" element={<ProductPage />} />
        <Route exact path="/cart" element={<CartPage />} />
        <Route path="/shipping" element={<ShippingPage />} />
        <Route exact path="/checkout-process" element={<CheckoutProcess />} />
        <Route path="/checkout-status" element={<CheckoutStatusPage />} />
        <Route exact path="/login-success" element={<LoginSuccess />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/developer" element={<DeveloperPage />} />
        <Route path="/cancel" element={<PreorderCancel />} />
        <Route path="/account-migration" element={<AccountMigration />} />
        <Route path="/warranty" element={<WarrantyPolicy />} />
        <Route path="/shipping-returns" element={<ShippingReturnPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<Terms />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </ErrorBoundary>
  );
};
const mapDispatchToProps = (dispatch) => ({
  retrieveProductsData: (products) => dispatch(retrieveProductsData(products)),
  retrievePlansData: (products) => dispatch(retrievePlansData(products)),
});

export default connect(null, mapDispatchToProps)(App);
