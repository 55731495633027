import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { addItem } from '../../redux/cart/cart.actions';
import CloudAnimation from '../animation/cloud';
import Price from '../price/price.component';
import productPlaceholder from '../../assets/product_placeholder.jpeg';
import './collection-item.scss';

const API_ENDPOINT = process.env.REACT_APP_STORE_API;

const CollectionItem = ({ item }) => {
  const { name, images, metadata, description } = item;
  const [prices, setPrices] = useState([]);
  useEffect(() => {
    const fetchProductPricesData = async () => {
      const response = await fetch(`${API_ENDPOINT}/allprices/${item.id}`);
      const json = await response.json();
      setPrices(json.body.data);
    };
    fetchProductPricesData();
  }, []);
  return (
    <Link className="collection-item" to={`/product/${item.id}`}>
      <>
        {!images || prices.length === 0 ? (
          <CloudAnimation />
        ) : (
          <>
            {item.metadata.Status === 'Out of Stock' && (
              <span className="status-ribbon">
                <h2>Out of Stock</h2>
              </span>
            )}
            <Box
              alignSelf="flex-start"
              className={`payment-tag ${
                metadata['Checkout Mode'] === 'payment_future'
                  ? 'payment-tag-visible'
                  : ''
              }`}
            >
              <p>Accepting Pre-Orders</p>
            </Box>

            <div
              className="image"
              style={{
                backgroundImage: `url(${images[0] ?? productPlaceholder})`,
              }}
            />
            <div className="collection-footer">
              <Flex
                className="product-title"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex flexDirection="column">
                  <h3 className="name">{name}</h3>
                  <span className="model">{metadata.Model}</span>
                </Flex>
                <div>
                  {prices && prices.length === 2 ? (
                    <h3 className="product-price">
                      {prices[0].nickname !== null ? (
                        <div className="prices-wrapper">
                          <span className="sale-price">
                            <Price
                              currency={prices[0].currency}
                              amount={prices[0].unit_amount}
                              interval={
                                prices[0].type === 'recurring'
                                  ? prices[0].recurring.interval
                                  : ''
                              }
                            />
                          </span>
                          <span className="original-price">
                            <Price
                              currency={prices[1].currency}
                              amount={prices[1].unit_amount}
                              interval={
                                prices[1].type === 'recurring'
                                  ? prices[1].recurring.interval
                                  : ''
                              }
                            />
                          </span>
                        </div>
                      ) : (
                        <div className="prices-wrapper">
                          <span className="sale-price">
                            <Price
                              currency={prices[1].currency}
                              amount={prices[1].unit_amount}
                              interval={
                                prices[1].type === 'recurring'
                                  ? prices[1].recurring.interval
                                  : ''
                              }
                            />
                          </span>
                          <span className="original-price">
                            <Price
                              currency={prices[0].currency}
                              amount={prices[0].unit_amount}
                              interval={
                                prices[0].type === 'recurring'
                                  ? prices[0].recurring.interval
                                  : ''
                              }
                            />
                          </span>
                        </div>
                      )}
                    </h3>
                  ) : prices && prices.length === 1 ? (
                    <span className="original-price">
                      <Price
                        currency={prices[0].currency}
                        amount={prices[0].unit_amount}
                        variant="regular"
                        interval={
                          prices[0].type === 'recurring'
                            ? prices[0].recurring.interval
                            : ''
                        }
                      />
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </Flex>

              <span className="short-description">{description}</span>

              <Box className="product-sku" alignSelf="flex-end">
                <p>SKU: {metadata.SKU}</p>
              </Box>
            </div>
          </>
        )}

        {/* <CustomButton onClick={() => addItem(item)} inverted>
        ADD TO CART
      </CustomButton> */}
      </>
    </Link>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addItem: (item) => dispatch(addItem(item)),
});
export default connect(null, mapDispatchToProps)(CollectionItem);
