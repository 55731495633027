import React from 'react';
import {
  Box,
  Heading,
  Flex,
  Icon,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tooltip,
} from '@chakra-ui/react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { HiCheckCircle, HiOutlineInformationCircle } from 'react-icons/hi';
import { selectPlans } from '../../redux/shop/shop.selectors';
import { ActionButton } from './ActionButton';
import PricingCard from './PricingCard';
import useWindowSize from '../../api/useWindowSize';
import './pricing-plans.styles.scss';

const PricingPlans = ({ orderNumber, plans }) => {
  const size = useWindowSize();

  // Sort items in a category
  plans.sort((a, b) => {
    const keyA = parseInt(a.metadata['Group Order'], 10);
    const keyB = parseInt(b.metadata['Group Order'], 10);
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });

  const status = plans.map((plan) => plan.metadata['Cloud Config']);
  const dataTrafic = plans.map((plan) => plan.metadata['Data Traffic / Month']);
  const dataStorage = plans.map((plan) => plan.metadata['Data Storage']);
  const maxDevices = plans.map((plan) => plan.metadata['Max # devices']);

  return (
    <div className="pricing-plans-page">
      {size.width >= 1250 && (
        <Box mt={12}>
          {orderNumber ? (
            <Flex
              className="pricing-plans-page--heading"
              flexDirection="column"
              alignItems="center"
            >
              <h2>
                Your EdgePi has been successfully purchased. Order : #
                {orderNumber}
              </h2>
              <p>
                Now that you have an EdgePi, unlock its full potential by
                picking a data plan that fits your need and start uploading your
                data onto the cloud.
              </p>
            </Flex>
          ) : (
            <Flex
              className="pricing-plans-page--heading"
              flexDirection="column"
              alignItems="center"
            >
              <h2>Flexible monthly plans for everyone.</h2>
              <p>
                Simple plans. Simple prices. Compare our plans and find yours.
              </p>
            </Flex>
          )}

          <TableContainer className="plans-table">
            <Table variant="striped" size="lg">
              <Thead>
                <Tr>
                  <Th />
                  {plans.length > 0
                    ? plans.map((item) => (
                        <Th className="plan-item" p={0} key={item.id}>
                          <PricingCard
                            label={item.metadata.Label}
                            data={{
                              id: item.id,
                              name: item.name,
                              features: item.metadata,
                              thumbnail: item.images[0],
                            }}
                          />
                        </Th>
                      ))
                    : ''}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Th className="table-heading">
                    Cloud Config
                    <Tooltip
                      hasArrow
                      label="Manage your EdgePi via Cloud. Explore and modify device configuration. See current status of each module live."
                      bg="#000"
                      color="#FFF"
                    >
                      <div className="icon-tooltip">
                        <Icon
                          color="#afafaf"
                          as={HiOutlineInformationCircle}
                          w={6}
                          h={6}
                        />
                      </div>
                    </Tooltip>
                  </Th>
                  {status.length > 0 &&
                    status.map((item, index) => (
                      <Td textAlign="center" key={index}>
                        {item && (
                          <Icon
                            color="#dc2f5c"
                            as={HiCheckCircle}
                            w={6}
                            h={6}
                            mt={2}
                          />
                        )}
                      </Td>
                    ))}
                </Tr>
                <Tr>
                  <Th className="table-heading">
                    Data Traffic / Month
                    <Tooltip
                      hasArrow
                      label="Amount of data traffic allowed per month between your EdgePi devices and EdgePi Cloud."
                      bg="#000"
                      color="#FFF"
                    >
                      <div className="icon-tooltip">
                        <Icon
                          color="#afafaf"
                          as={HiOutlineInformationCircle}
                          w={6}
                          h={6}
                        />
                      </div>
                    </Tooltip>
                  </Th>
                  {dataTrafic.length > 0 &&
                    dataTrafic.map((item) => (
                      <Td textAlign="center" key={item}>
                        {item}
                      </Td>
                    ))}
                </Tr>
                <Tr>
                  <Th className="table-heading">
                    Data Storage
                    <Tooltip
                      hasArrow
                      label="Total amount of data that can be stored in EdgePi Cloud for all of your EdgePi devices."
                      bg="#000"
                      color="#FFF"
                    >
                      <div className="icon-tooltip">
                        <Icon
                          color="#afafaf"
                          as={HiOutlineInformationCircle}
                          w={6}
                          h={6}
                        />
                      </div>
                    </Tooltip>
                  </Th>
                  {dataStorage.length > 0 &&
                    dataStorage.map((item) => (
                      <Td textAlign="center" key={item}>
                        {item}
                      </Td>
                    ))}
                </Tr>
                <Tr>
                  <Th className="table-heading">
                    Max. # Devices
                    <Tooltip
                      hasArrow
                      label="Total number of EdgePi devices that can be assigned to this plan."
                      bg="#000"
                      color="#FFF"
                    >
                      <div className="icon-tooltip">
                        <Icon
                          color="#afafaf"
                          as={HiOutlineInformationCircle}
                          w={6}
                          h={6}
                        />
                      </div>
                    </Tooltip>
                  </Th>
                  {maxDevices.length > 0 &&
                    maxDevices.map((item, index) => (
                      <Td textAlign="center" key={index}>
                        {item}
                      </Td>
                    ))}
                </Tr>
              </Tbody>
            </Table>
            <h3 className="contact-us">
              Not Enough?{' '}
              <a
                href="https://www.edgepi.com/contact"
                target="_blank"
                rel="noreferrer"
              >
                Contact us
              </a>{' '}
              <span>for higher data plans.</span>
            </h3>
          </TableContainer>
        </Box>
      )}

      {size.width < 1250 && (
        <Box
          as="section"
          px={{
            base: '4',
            md: '4',
          }}
        >
          {orderNumber ? (
            <Box className="plans-mobile-container">
              <h2>
                Your EdgePi has been successfully purchased. Order : #
                {orderNumber}
              </h2>
              <p>
                Now that you have an EdgePi, unlock its full potential by
                picking a data plan that fits your need and start uploading your
                data onto the cloud.
              </p>
            </Box>
          ) : (
            <Box className="plans-mobile-container">
              <h2>Flexible monthly plans for everyone.</h2>
              <p>
                Simple plans. Simple prices. Compare our plans and find yours.
              </p>
            </Box>
          )}
          <SimpleGrid
            columns={{
              base: 1,
              lg: 3,
            }}
            spacing={{
              base: '8',
              lg: '8',
            }}
            mx="auto"
            autoRows="column"
            justifyItems="center"
            alignItems="center"
          >
            {plans.length > 0
              ? plans.map((item) => (
                  <PricingCard
                    key={item.id}
                    label={item.metadata.Label}
                    data={{
                      id: item.id,
                      name: item.name,
                      features: item.metadata,
                      thumbnail: item.images[0],
                    }}
                  />
                ))
              : ''}

            <Box
              position="relative"
              px="6"
              pb="6"
              pt="16"
              overflow="hidden"
              shadow="lg"
              maxW="md"
              width="100%"
              height="100%"
              rounded={{
                sm: 'xl',
              }}
            >
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="flex-start"
                spacing={6}
                height="100%"
              >
                <Heading className="plan-name" size="lg" fontWeight="medium">
                  NOT ENOUGH?
                </Heading>
                <p>Contact us for higher data plans</p>
                <ActionButton
                  onClick={() =>
                    window.open('https://www.edgepi.com/contact-5', '_blank')
                  }
                  className="new-plan-contact"
                >
                  Contact Us
                </ActionButton>
              </Flex>
            </Box>
          </SimpleGrid>
        </Box>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  plans: selectPlans,
});

export default connect(mapStateToProps, null)(PricingPlans);
