import { ShopActionTypes } from './shop.types';

const INITIAL_STATE = {
  collections: [],
  plans: [],
};

const shopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ShopActionTypes.RETRIEVE_PRODUCTS_DATA:
      return {
        ...state,
        collections: action.payload,
      };
    case ShopActionTypes.RETRIEVE_PLANS_DATA:
      return {
        ...state,
        plans: action.payload,
      };
    default:
      return state;
  }
};

export default shopReducer;
