import React from 'react';
import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

const CheckOutModal = ({
  isOpen,
  onClose,
  onCreateAccount,
  onCheckOut,
  type,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Hey! You are not logged in</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {type === 'subscription' ? (
            <p>Please sign up before subscribing a plan</p>
          ) : (
            <p>You can login or continue checkout as guest</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Flex flexWrap="wrap">
            <Button
              backgroundColor="#dc2f5c"
              color="#FFF"
              _hover={{ bg: '#bd214b' }}
              mr={3}
              mb={3}
              onClick={onCreateAccount}
            >
              Sign In/Sign Up
            </Button>
            {type !== 'subscription' && (
              <Button
                backgroundColor="#dc2f5c"
                color="#FFF"
                _hover={{ bg: '#bd214b' }}
                mr={3}
                mb={3}
                onClick={() => {
                  onClose();
                  onCheckOut();
                }}
              >
                Checkout as a Guest
              </Button>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CheckOutModal;
