import { ErrorActionTypes } from './error.types';

const INITIAL_STATE = {
  currentError: [],
};

const errorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ErrorActionTypes.SET_CURRENT_ERROR:
      return {
        ...state,
        currentError: action.payload,
      };
    default:
      return state;
  }
};

export default errorReducer;
