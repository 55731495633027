import { UserActionTypes } from './user.types';

export const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user,
});

export const setCurrentSession = (mode) => ({
  type: UserActionTypes.SET_CURRENT_SESSION,
  payload: mode,
});

export const signOutUser = () => ({
  type: UserActionTypes.SIGN_OUT_USER,
});
