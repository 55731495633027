import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { createStructuredSelector } from 'reselect';
import { Box, Container, Flex, Icon } from '@chakra-ui/react';
import { ImGift } from 'react-icons/im';
import { selectCollections } from '../../redux/shop/shop.selectors';
import CategoryFilter from '../../components/category-filter/category-filter.component';
import CollectionPreview from '../../components/collection-preview/collection-preview.component';
import './shop.styles.scss';

const ShopPage = ({ collections }) => {
  const [categories, setCategories] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [activeCategory, setActiveCategory] = useState('All');

  // Sort items in a category
  const sortItems = (arr, metakey) => {
    arr.sort((a, b) => {
      const keyA = parseInt(a.metadata[metakey], 10);
      const keyB = parseInt(b.metadata[metakey], 10);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return arr;
  };

  useEffect(() => {
    if (collections.length > 0) {
      sortItems(collections, 'Category Number');
      const catSet = [
        ...new Set(
          collections
            .filter((item) => item.metadata.Status !== 'Unlisted')
            .map((x) => x.metadata.Category)
        ),
      ];
      setCategories(catSet);
      setFiltered(catSet);
    }
    window.scrollTo(0, 0);
  }, [collections]);

  return (
    <Box className="shop-page">
      {process.env.REACT_APP_SALE_ACTIVE === 'true' && (
        <div className="text-banner">
          <h1>
            <Icon w={8} h={8} as={ImGift} /> CHRISTMAS SALE{' '}
            <Icon w={8} h={8} as={ImGift} />
          </h1>
        </div>
      )}

      {categories.length > 1 && ( // only show the categories filter if more than 1
        <CategoryFilter
          collections={collections}
          categories={categories}
          setFiltered={setFiltered}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      )}

      <motion.div layout>
        <AnimatePresence>
          {filtered.length > 0 &&
            filtered.map((category, index) => (
              <Container
                maxW={1400}
                key={index}
                className="shop-page--container"
              >
                {category && <h1>{category}</h1>}
                <Flex
                  className="egdepi-container"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {sortItems(collections, 'Group Order')
                    .filter(
                      (collection) => collection.metadata.Category === category
                    )
                    .filter((item) => item.metadata.Status !== 'Unlisted')
                    .map((item) => (
                      <CollectionPreview
                        key={item.id}
                        status={item.metadata.Status}
                        item={item}
                      />
                    ))}
                </Flex>
              </Container>
            ))}
        </AnimatePresence>
      </motion.div>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  collections: selectCollections,
});
export default connect(mapStateToProps)(ShopPage);
