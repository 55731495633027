import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import './policy.styles.scss';

const PrivacyPolicy = () => (
  <Box w="100%" className="policy-page">
    <Container maxW={1400}>
      <h1>Our Policy</h1>
      <p>
        EdgePi, a division of OSENSA Innovations Corp., appreciates your
        interest in our website as well as in our company and our products. The
        protection of your privacy during the processing of any Personal
        Information obtained from your visit to our website is an important part
        of our business practices. We adhere to the privacy legislation of the
        province of British Columbia, Canada. The EdgePi website may contain
        links to other websites of other providers who do not conform to this
        privacy policy. EdgePi cannot control how the providers of linked
        websites handle your information. If you have any questions in this
        regard, please contact these companies directly. EdgePi is not
        responsible for the privacy policies or the content of these websites.
      </p>

      <h2>USE AND DISTRIBUTION OF PERSONAL DATA</h2>
      <p>
        EdgePi servers routinely save your IP address, the website you are
        currently on and the browser you are using. If you have logged in with a
        username and password, your last login session and password is saved,
        and your page view activities may be recorded. Additionally, personal
        data is only saved when you provide it as part of opening an account,
        placing an order, requesting support, or completing a survey. EdgePi
        uses your personal data for the technical administration of the website,
        customer support, and marketing. EdgePi may contact you by email from
        time-to-time for marketing reasons. You may request to be removed from
        our marketing list at any time, and we will make a reasonable effort to
        remove your Personal Information from our database in such a way that
        you will not receive future communications from us. In addition, if you
        notify us that Personal Information previously submitted to our site is
        no longer accurate, we will make a reasonable effort to make appropriate
        corrections. EdgePi does not distribute or sell Personal Information to
        third parties for the purpose of allowing them to market their products
        and services to you; moreover, the distribution of personal data to
        government institutions and bodies is only undertaken under compulsory
        nationally legislated conditions.
      </p>
      <h2>COOKIES</h2>
      <p>
        EdgePi may use cookies to store your preferences and to optimally
        represent the website. Cookies are small files which are temporarily
        stored on your hard drive. The information contained in cookies
        simplifies navigation and improves the user-friendliness of a website.
        You can view our website without cookies. Our cookies contain no
        personal information, protecting your privacy. Most browsers
        automatically accept cookies. You can prevent the saving of cookies on
        your hard drive by setting the &quot;do not accept cookies&quot; option
        in your browser settings. Please see your browser publisher&apos;s help
        with regards to the individual browser configurations. Should you not
        accept cookies, please note that this may limit the functions of our
        offering.
      </p>

      <h2>RIGHT TO INFORMATION</h2>
      <p>
        On request, EdgePi will inform you in writing as soon as possible and
        with regards to current legislation, whether and which of your personal
        data is stored by us. If you are a registered user, we offer you the
        deletion or alteration of this data on request to EdgePi. Should the
        wrong information be stored by us, even though we strive to store
        up-to-date and correct information, we will correct it on request.
      </p>

      <h2>GOOGLE ANALYTICS</h2>
      <p>
        This website uses Google Analytics, a web analytics service provided by
        Google, Inc. (&quot;Google&quot;). Google Analytics uses
        &quot;cookies&quot;, which are text files placed on your computer, to
        help the website analyze how users use the site. The information
        generated by the cookie about your use of the website (including your IP
        address) will be transmitted to and stored by Google on servers in the
        United States. Google will use this information for the purpose of
        evaluating your use of the website, compiling reports on website
        activity for website operators and providing other services relating to
        website activity and internet usage. Google may also transfer this
        information to third parties where required to do so by law, or where
        such third parties process the information on Google&apos;s behalf.
        Google will not associate your IP address with any other data held by
        Google. You may refuse the use of cookies by selecting the appropriate
        settings on your browser, however please note that if you do this you
        may not be able to use the full functionality of this website. By using
        this website, you consent to the processing of data about you by Google
        in the manner and for the purposes set out above.
      </p>
    </Container>
  </Box>
);

export default PrivacyPolicy;
