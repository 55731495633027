/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Flex,
  Icon,
  Input,
  Select,
  Stack,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { MdOutlineKeyboardBackspace, MdOutlineInfo } from 'react-icons/md';
import GoogleAutoComplete from 'react-auto-complete-address-fields';
import useForm from '../../../api/useForm';
import { selectCurrentUser } from '../../../redux/user/user.selectors';
import { setCurrentUser } from '../../../redux/user/user.actions';
import { setCurrentError } from '../../../redux/error/error.actions';
import updateCustomerInfoFromStripe from '../../../api/stripe/updateCustomerInfoFromStripe';
import createStripeCustomer from '../../../api/stripe/createStripeCustomer';
import CustomButton from '../../../components/button/custom-button.component';
import data from '../../../data/allowed_countries.json';
import 'react-auto-complete-address-fields/build/GoogleAutoComplete.css';

const countries = data.data;
const ShippingEditComp = ({
  editMode,
  stripeData,
  currentUser,
  setCurrentUser,
  setCurrentError,
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setButtonLoading] = useState(false);
  const { onChange, onSubmit, onSelectLocation, values } = useForm(
    // eslint-disable-next-line no-use-before-define
    updateInfoCallback,
    {
      customer_name: stripeData ? stripeData.name : '',
      customer_email: stripeData ? stripeData.email : '',
      customer_phone: stripeData ? stripeData.phone : '',
      shipping_city:
        stripeData && stripeData.shipping
          ? stripeData.shipping.address.city
          : '',
      shipping_country:
        stripeData && stripeData.shipping
          ? stripeData.shipping.address.country
          : '',

      shipping_address_line1:
        stripeData && stripeData.shipping
          ? stripeData.shipping.address.line1
          : '',
      shipping_address_line2:
        stripeData && stripeData.shipping
          ? stripeData.shipping.address.line2
          : '',
      shipping_postal_code:
        stripeData && stripeData.shipping
          ? stripeData.shipping.address.postal_code
          : '',
      shipping_state:
        stripeData && stripeData.shipping
          ? stripeData.shipping.address.state
          : '',
    }
  );

  async function updateInfoCallback() {
    setButtonLoading(true);
    if (currentUser) {
      const options = {};

      if (currentUser.accounts) {
        // for logged user
        options.stripeCustomerId = currentUser.accounts.stripe_customer_id;
        options.userEmail = currentUser.userEmail; // cognito email
        options.accountID = currentUser.accounts.account_id;
      } else {
        // for returning guest
        options.stripeCustomerId = currentUser.stripeCustomerId;
        options.userEmail = currentUser.userEmail; // guest user email
        options.accountID = currentUser.accountId;
      }
      const response = await updateCustomerInfoFromStripe(
        options.stripeCustomerId,
        options.userEmail,
        options.accountID,
        values
      );

      if (response.status === 200) {
        toast({
          title: 'Update user information successful!',
          status: 'success',
          duration: 5000,
          position: 'top',
          isClosable: true,
        });
        editMode(false);
        setButtonLoading(false);
      } else {
        toast({
          title: 'Failed to update user information',
          status: 'error',
          duration: 5000,
          position: 'top',
          isClosable: true,
        });
        setButtonLoading(false);
      }
    } else {
      // undefined user => new guest user => create a new Stripe account for guest
      const response = await createStripeCustomer(
        values.customer_email,
        values
      );

      if (response && response.status === 200) {
        // Create user successful!
        setCurrentUser({
          ...currentUser,
          stripeCustomerId: response.response.id,
          userEmail: response.response.email,
          accountId: response.account_id,
        });
        editMode(false);
        setButtonLoading(false);
      } else {
        toast({
          title: 'Failed to update user information',
          description: response.message || '',
          status: 'error',
          duration: 5000,
          position: 'top',
          isClosable: true,
        });
        setButtonLoading(false);
      }
    }

    return null;
  }

  const handleInputChange = (address) => {
    const city = address.address_components.find(
      (item) =>
        item.types[0] === 'locality' ||
        item.types[0] === 'administrative_area_level_1'
    );

    const state = address.address_components.find(
      (item) => item.types[0] === 'administrative_area_level_1'
    );

    const country = address.address_components.find(
      (item) => item.types[0] === 'country'
    );

    const postalCode = address.address_components.find(
      (item) => item.types[0] === 'postal_code'
    );

    const shippingCountry = countries.find(
      (item) => item.country === country.long_name
    );
    if (!shippingCountry) {
      toast({
        title: 'EdgePi is not available in your country.',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      return false;
    }

    onSelectLocation([
      address.name, // address line 1
      city.long_name, // city
      state.long_name, // state
      country.short_name, // country code
      postalCode ? postalCode.long_name : '', // postal code
    ]);
    return true;
  };

  return (
    <Container maxW={800}>
      <div className="text-banner">
        <Icon
          className="back-btn"
          w={12}
          h={12}
          as={MdOutlineKeyboardBackspace}
          onClick={() => {
            editMode(false);
            navigate(-1);
          }}
        />

        <h2>Lets Collect your Information .</h2>
      </div>
      <form onSubmit={onSubmit}>
        <div className="contact-information">
          <h3>
            Contact Information{' '}
            <Tooltip
              shouldWrapChildren
              hasArrow
              label="These are the details we will use to contact you. Your login details may be different."
            >
              <Icon w={6} h={6} as={MdOutlineInfo} />
            </Tooltip>
          </h3>

          <Flex justifyContent="space-between" flexWrap="wrap">
            <div>
              <h4>
                Contact Email Address <span className="asterisk">*</span>
              </h4>

              <Input
                w={300}
                className="custom-input"
                value={values.customer_email}
                onChange={onChange('customer_email')}
                placeholder="Email Address"
                type="email"
                size="sm"
                disabled={currentUser && !currentUser.isLoggedIn}
                required
              />
            </div>
            <div>
              <h4>Phone Number</h4>

              <Input
                w={300}
                className="custom-input"
                value={values.customer_phone}
                onChange={onChange('customer_phone')}
                placeholder="Phone No."
                type="text"
                size="sm"
              />
            </div>
          </Flex>
        </div>

        <div className="shipping-information">
          <h3>
            Shipping Address{' '}
            <Tooltip
              shouldWrapChildren
              hasArrow
              label="Your shipping address is collected in order to calculate your shipping rates. We currently only ship to Canada, US, EU and Australia."
            >
              <Icon w={6} h={6} as={MdOutlineInfo} />
            </Tooltip>
          </h3>

          <p>
            Name <span className="asterisk">*</span>
          </p>
          <Input
            className="custom-input"
            value={values.customer_name}
            onChange={onChange('customer_name')}
            placeholder="First and Last Name"
            type="text"
            size="sm"
            required
          />

          <div>
            <Stack mb={8}>
              <Stack mb={12} className="custom-auto-complete-input">
                <p>Search for your Address</p>
                <GoogleAutoComplete
                  apiKey={process.env.REACT_APP_GOOGLE_PLACE_API_KEY}
                  id="location"
                  fields={{
                    streetAddress: 'route',
                    streetAddress2: 'administrative_area_level_4',
                    locality: 'locality',
                    cityOrState: 'administrative_area_level_1',
                    postalcode: 'postal_code',
                    country: 'country',
                  }}
                  callbackFunction={handleInputChange}
                />
                <Box className="manual-address-info">
                  <Flex justifyContent="space-between" flexWrap="wrap">
                    <div>
                      <p>
                        Address Line 1 <span className="asterisk">*</span>
                      </p>
                      <Input
                        className="custom-input"
                        w={300}
                        value={values.shipping_address_line1}
                        onChange={onChange('shipping_address_line1')}
                        placeholder="Street, PO Box"
                        type="text"
                        size="sm"
                        required
                      />
                    </div>
                    <div>
                      <p>Address Line 2</p>
                      <Input
                        w={300}
                        className="custom-input"
                        value={values.shipping_address_line2}
                        onChange={onChange('shipping_address_line2')}
                        placeholder="Apt, Suite, Unit, Building"
                        type="text"
                        size="sm"
                      />
                    </div>
                  </Flex>

                  <Flex justifyContent="space-between" flexWrap="wrap">
                    <div>
                      <p>
                        City <span className="asterisk">*</span>
                      </p>
                      <Input
                        w={300}
                        className="custom-input"
                        value={values.shipping_city}
                        onChange={onChange('shipping_city')}
                        placeholder="City, District, Suburb, Tow"
                        type="text"
                        size="sm"
                        required
                      />
                    </div>
                    <div>
                      <p>
                        Province/State <span className="asterisk">*</span>
                      </p>
                      <Input
                        w={300}
                        className="custom-input"
                        value={values.shipping_state}
                        onChange={onChange('shipping_state')}
                        placeholder="Province, State, Region"
                        type="text"
                        size="sm"
                        required
                      />
                    </div>
                  </Flex>

                  <Flex justifyContent="space-between" flexWrap="wrap">
                    <div>
                      <p>
                        Postal / Zip Code
                        {values.shipping_country === 'US' && (
                          <span className="asterisk">*</span>
                        )}
                        {values.shipping_country === 'CA' && (
                          <span className="asterisk">*</span>
                        )}
                      </p>
                      <Input
                        w={300}
                        className="custom-input"
                        value={values.shipping_postal_code}
                        onChange={onChange('shipping_postal_code')}
                        placeholder="Postal Code, Zip Code"
                        type="text"
                        size="sm"
                        required={
                          values.shipping_country === 'US' ||
                          values.shipping_country === 'CA'
                        }
                      />
                    </div>
                    <div>
                      <p>
                        Country / Region <span className="asterisk">*</span>
                      </p>

                      <Select
                        onChange={onChange('shipping_country')}
                        value={values.shipping_country}
                        size="sm"
                        w={300}
                        className="custom-input"
                        placeholder="Country"
                        isRequired
                      >
                        {countries.map((country, index) => (
                          <option key={index} value={country.code}>
                            {country.country}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </Flex>
                </Box>
              </Stack>
            </Stack>
          </div>
        </div>
        <span className="asterisk">* Mandatory Field</span>
        <div className="form-btn-group">
          <CustomButton
            isLoading={isLoading}
            type="submit"
            variant="red"
            width={300}
            mt={12}
          >
            Submit
          </CustomButton>
        </div>
      </form>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentError: (error) => dispatch(setCurrentError(error)),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingEditComp);
