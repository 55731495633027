import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setCurrentUser } from '../../redux/user/user.actions';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { clearNormalPurchaseItems } from '../../redux/cart/cart.actions';
import { removeQuickbuyItem } from '../../redux/quickbuy/quickbuy.actions';
import checkAuthentication from '../../api/checkAuthentication';
import checkPreOrderStatus from '../../api/checkPreOrderStatus';
import checkOrderStatus from '../../api/checkOrderStatus';
import CustomButton from '../../components/button/custom-button.component';
import { ReactComponent as SuccessIcon } from '../../assets/success.svg';
import { ReactComponent as ErrorIcon } from '../../assets/cloud-error.svg';
import CloudAnimation from '../../components/animation/cloud';
import './checkout-status.styles.scss';

const STORE_API_ENDPOINT = process.env.REACT_APP_STORE_API;

const CheckoutStatusPage = ({
  currentUser,
  setCurrentUser,
  clearNormalPurchaseItems,
  removeQuickbuyItem,
}) => {
  const [checkoutStatus, setCheckoutStatus] = useState('');
  const [checkoutMode, setCheckoutMode] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const sessionId = queryParams.get('session_id');

  useEffect(() => {
    if (!sessionId) {
      navigate('/404');
    }

    const getStripeCustomerID = async () => {
      const user = await checkAuthentication();
      const myHeaders = new Headers();
      if (user) {
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        myHeaders.append('Authorization', `Bearer ${accessToken}`);
      }
      myHeaders.append('Content-Type', 'application/json');

      const userEmail = currentUser ? currentUser.userEmail : '';
      // new guest user: ''

      const options = {
        headers: myHeaders,
        method: 'GET',
        redirect: 'follow',
      };
      const response = await fetch(
        `${STORE_API_ENDPOINT}/checkout-status?session_id=${sessionId}&user_email=${userEmail}`,
        options
      );

      const json = await response.json();

      if (response.status === 200) {
        const retry = (fn, retriesLeft = 50, interval = 200) => {
          return new Promise((resolve, reject) => {
            fn()
              .then((res) => {
                if (!res || res.status !== 200) {
                  setTimeout(() => {
                    if (retriesLeft === 1) {
                      console.log('maximum retries exceeded');
                      console.log('can not get the order number for now');
                      setCheckoutStatus(res.status);
                      return;
                    }

                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                  }, interval);
                } else {
                  if (Object.keys(json.account).length > 0) {
                    setCurrentUser({
                      ...currentUser,
                      stripeCustomerId: json.account
                        ? json.account.stripe_customer_id
                        : '',
                      accountId: json.account ? json.account.account_id : '',
                    });
                  }
                  // Show order number and status
                  setOrderNumber(res.zoho_sales_order_number);
                  setOrderStatus(res.zoho_sales_order_status);

                  setCheckoutStatus(json.event_status_code);
                  setCheckoutMode(json.checkout_mode); // show a equivalent message on the page
                  resolve();
                }
              })
              .catch((error) => {
                setTimeout(() => {
                  if (retriesLeft === 1) {
                    // maximum retries exceeded
                    console.log('can not get the order number for now', error);
                    reject(error);
                    setCheckoutStatus(404); // show order failed message
                    return;
                  }
                  retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
              });
          });
        };

        retry(() =>
          json.checkout_mode === 'payment'
            ? checkOrderStatus(
                sessionId,
                currentUser.isLoggedIn,
                currentUser.userEmail
              )
            : checkPreOrderStatus(sessionId, currentUser.isLoggedIn)
        );
      } else {
        setCheckoutStatus(response.status);
      }
    };
    // cleanup session
    clearNormalPurchaseItems();
    removeQuickbuyItem();
    getStripeCustomerID();
  }, []);

  return (
    <div className="status-page">
      {checkoutStatus && checkoutStatus === 200 ? (
        <div className="status-page--content">
          <div className="success-icon">
            <SuccessIcon />
          </div>
          {checkoutMode && checkoutMode === 'setup' ? (
            <div className="status-page-detail">
              {orderStatus === 'draft' && (
                <h1> PRESALE REGISTRATION SUCCESSFUL.</h1>
              )}
              {orderNumber && (
                <h2>
                  Order Number : <span>{orderNumber}</span>
                </h2>
              )}
              {orderStatus === 'cancelled' && (
                <h2>
                  Order Status : <span>{orderStatus}</span>
                </h2>
              )}

              {orderStatus === 'draft' && (
                <>
                  <h3>
                    You have been successfully added to the EdgePi Waiting List.
                  </h3>
                  <p>
                    You will be notified through email when your EdgePi Device
                    is ready to be ordered. A link will be included within that
                    email for you to complete the checkout process if you decide
                    to continue.
                  </p>
                  <p>
                    Your card will only be charged if you decide to continue. If
                    you change your mind, you can cancel your pre-order.
                  </p>
                </>
              )}
              {orderStatus === 'cancelled' && (
                <h3>This order has been cancelled</h3>
              )}

              <div className="success-button-group">
                <CustomButton variant="black" onClick={() => navigate('/shop')}>
                  Back to Shop
                </CustomButton>

                <a
                  href={`${process.env.REACT_APP_PORTAL_URL}/orders`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <CustomButton variant="red">Manage Orders</CustomButton>
                </a>
              </div>
            </div>
          ) : (
            <div className="status-page-detail">
              <h1>Congratulations, you’re all set ! 🎉</h1>
              {orderNumber && (
                <h2>
                  Order Number : <span>{orderNumber}</span>
                </h2>
              )}
              <p>
                The team at EdgePi would like to take the opportunity to
                personally thank you and welcome you on board. Our engineers are
                hard at work creating your device ready and sent to you as soon
                as possible.
              </p>
              <p>
                You will receive an confirmation email shortly at{' '}
                <span>{currentUser.userEmail}</span>.
              </p>
              <div className="success-button-group">
                <CustomButton variant="black" onClick={() => navigate('/shop')}>
                  Back to Shop
                </CustomButton>
                <a
                  href={`${process.env.REACT_APP_PORTAL_URL}/orders`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <CustomButton variant="red">Manage Orders</CustomButton>
                </a>
              </div>
            </div>
          )}
        </div>
      ) : checkoutStatus && checkoutStatus !== 200 ? (
        <div className="status-page--content">
          <div className="success-icon">
            <ErrorIcon />
          </div>
          <div className="status-page-detail">
            <h1>ORDER FAILED!</h1>
            <h3>
              Oops! An error occured while processing the order. You can try
              again or contact us for support.
            </h3>

            <div className="status-page-button-group">
              <CustomButton variant="red" onClick={() => navigate('/shop')}>
                Back to Shop
              </CustomButton>
            </div>
          </div>
        </div>
      ) : (
        <CloudAnimation />
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  removeQuickbuyItem: () => dispatch(removeQuickbuyItem()),
  clearNormalPurchaseItems: () => dispatch(clearNormalPurchaseItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStatusPage);
