/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stepper } from 'react-form-stepper';
import { Box, Container, VStack, Button, Flex } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import './checkout-process.styles.scss';
import PricingPlans from '../../components/pricing-plans/PricingPlans';
import { setCurrentUser } from '../../redux/user/user.actions';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { removeQuickbuyItem } from '../../redux/quickbuy/quickbuy.actions';
import { clearNormalPurchaseItems } from '../../redux/cart/cart.actions';
import checkAuthentication from '../../api/checkAuthentication';
import checkOrderStatus from '../../api/checkOrderStatus';
import CloudAnimation from '../../components/animation/cloud';
import CustomButton from '../../components/button/custom-button.component';

const STORE_API_ENDPOINT = process.env.REACT_APP_STORE_API;
// const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;
const queryParams = new URLSearchParams(window.location.search);
const sessionId = queryParams.get('session_id');
const step = queryParams.get('step');

const CheckoutProcess = ({
  removeQuickbuyItem,
  clearNormalPurchaseItems,
  currentUser,
  setCurrentUser,
}) => {
  const [checkoutStatus, setCheckoutStatus] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [activeStep, setActiveStep] = useState(step ?? 1);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionId) {
      navigate('/404');
    }

    const getStripeCustomerID = async () => {
      // Get Stripe customer id from session id. Save customer id in redux persisted store
      const user = await checkAuthentication();
      const myHeaders = new Headers();
      if (user) {
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        myHeaders.append('Authorization', `Bearer ${accessToken}`);
      }
      myHeaders.append('Content-Type', 'application/json');

      const options = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };
      const userEmail = currentUser ? currentUser.userEmail : '';
      const response = await fetch(
        `${STORE_API_ENDPOINT}/checkout-status?session_id=${sessionId}&user_email=${userEmail}`,
        options
      );
      const json = await response.json();
      if (response.status === 200) {
        const retry = (fn, retriesLeft = 30, interval = 200) => {
          return new Promise((resolve, reject) => {
            fn()
              .then((res) => {
                if (!res || res.status !== 200) {
                  setTimeout(() => {
                    if (retriesLeft === 1) {
                      console.log('maximum retries exceeded');
                      console.log('can not get the order number for now');
                      setCheckoutStatus(res.status);
                      return;
                    }

                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                  }, interval);
                } else {
                  if (Object.keys(json.account).length > 0) {
                    setCurrentUser({
                      ...currentUser,
                      stripeCustomerId: json.account
                        ? json.account.stripe_customer_id
                        : '',
                      accountId: json.account ? json.account.account_id : '',
                      userEmail: currentUser ? currentUser.userEmail : '',
                    });
                  }
                  setCheckoutStatus(res.status);
                  // Show order number and status
                  setOrderNumber(res.zoho_sales_order_number);
                  // setOrderStatus(res.zoho_sales_order_status);

                  resolve();
                }
              })
              .catch((error) => {
                setTimeout(() => {
                  if (retriesLeft === 1) {
                    // maximum retries exceeded
                    console.log('can not get the order number for now', error);
                    reject(error);
                    return;
                  }
                  retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
              });
          });
        };

        retry(() =>
          checkOrderStatus(
            sessionId,
            currentUser.isLoggedIn,
            currentUser.userEmail
          )
        );
      } else {
        setCheckoutStatus(response.status);
      }
    };
    if (step && step === '2') {
      setActiveStep(2);
    } else {
      getStripeCustomerID();
    }

    // remove all items in session
    removeQuickbuyItem();
    clearNormalPurchaseItems();
  }, []);

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  // const handleRedirect = () => {
  //   window.open(PORTAL_URL, '_blank');
  // };

  if (activeStep === 1) {
    return (
      <Box w="100%" className="checkout-process-page">
        {checkoutStatus && checkoutStatus === 200 ? (
          <Container maxW={1400}>
            <Stepper
              steps={[
                { label: 'Purchase EdgePi' },
                { label: 'Subscribe a Plan' },
                { label: 'Complete' },
              ]}
              activeStep={activeStep}
              connectorStateColors
              connectorStyleConfig={{
                activeColor: '#dc2f5c',
              }}
              styleConfig={{
                activeBgColor: '#dc2f5c',
                completedBgColor: '#bd214b',
                labelFontSize: '20px',
              }}
            />

            <VStack py={4}>
              <PricingPlans orderNumber={orderNumber} />
            </VStack>

            <Flex
              width="100%"
              justifyContent="center"
              className="steps-btn-group"
            >
              <Button size="sm" onClick={handleNextStep}>
                {activeStep === 1 ? 'Skip' : 'Finish'}
              </Button>
            </Flex>
          </Container>
        ) : checkoutStatus && checkoutStatus !== 200 ? (
          <Container maxW={1400}>
            <div className="checkout-process-page-error">
              <h1>ORDER FAILED!</h1>
              <h3>
                Oops! An error occured while processing the order. You can try
                again or contact us for support.
              </h3>
              <CustomButton variant="red" onClick={() => navigate('/shop')}>
                Back to Shop
              </CustomButton>
            </div>
          </Container>
        ) : (
          <Container maxW={300} mt={24}>
            <CloudAnimation />
          </Container>
        )}
      </Box>
    );
  }
  if (activeStep === 2) {
    return (
      <Box w="100%" className="checkout-process-page">
        <Container maxW={1400}>
          <Stepper
            steps={[
              { label: 'Purchase EdgePi' },
              { label: 'Subscribe a Plan' },
              { label: 'Complete' },
            ]}
            activeStep={activeStep}
            connectorStateColors
            connectorStyleConfig={{
              activeColor: '#dc2f5c',
            }}
            styleConfig={{
              activeBgColor: '#dc2f5c',
              completedBgColor: '#bd214b',
              labelFontSize: '20px',
            }}
          />

          <Flex
            width="100%"
            justifyContent="center"
            className="completed-container"
            flexDirection="column"
            alignItems="center"
          >
            <Container className="completed-container--body" p={0} maxW="100%">
              <h1>Congratulations, you’re all set ! 🎉</h1>
              <h5>
                The team at EdgePi would like to take the opportunity to
                personally thank you and welcome you on board. Our engineers are
                hard at work creating your device ready and sent to you as soon
                as possible.
              </h5>
              <h5>
                You will receive an confirmation email shortly at{' '}
                <span>{currentUser.userEmail}</span>.
              </h5>
              {/* <div className="custom-btn" onClick={() => handleRedirect()}>
                GO TO THE PORTAL
              </div> */}
            </Container>
            <div className="success-button-group">
              <CustomButton variant="black" onClick={() => navigate('/shop')}>
                Back to Shop
              </CustomButton>

              <a
                href={`${process.env.REACT_APP_PORTAL_URL}/orders`}
                target="_blank"
                rel="noreferrer"
              >
                <CustomButton variant="red">Manage Orders</CustomButton>
              </a>
            </div>
          </Flex>
        </Container>
      </Box>
    );
  }
  return null;
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  removeQuickbuyItem: () => dispatch(removeQuickbuyItem()),
  clearNormalPurchaseItems: () => dispatch(clearNormalPurchaseItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutProcess);
