import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { CardBadge } from './CardBadge';

export const Card = (props) => {
  const { children, label, view, ...rest } = props;
  return (
    <Box
      // bg={useColorModeValue('white', 'gray.700')}
      position="relative"
      px="6"
      pb="6"
      pt={view === 'desktop' ? '4' : '16'}
      overflow="hidden"
      shadow={view === 'desktop' ? '' : 'lg'}
      maxW={view === 'desktop' ? '300px' : 'md'}
      width="100%"
      className=""
      {...rest}
    >
      {label && (
        <CardBadge variant={view === 'desktop' ? 'regular' : 'ribbon'}>
          {label}
        </CardBadge>
      )}
      {children}
    </Box>
  );
};
