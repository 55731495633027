import { QuickbuyActionTypes } from './quickbuy.types';

export const addQuickbuyItem = (item) => ({
  type: QuickbuyActionTypes.ADD_QUICKBUY_ITEM,
  payload: item,
});

export const removeQuickbuyItem = (item) => ({
  type: QuickbuyActionTypes.REMOVE_QUICKBUY_ITEM,
  payload: item,
});
