import { CartActionTypes } from './cart.types';

export const toggleCartHidden = () => ({
  type: CartActionTypes.TOGGLE_CART_HIDDEN, // dont need payload here
});

export const addItem = (item) => ({
  type: CartActionTypes.ADD_ITEM,
  payload: item,
});

export const addItems = (items) => ({
  type: CartActionTypes.ADD_ITEMS,
  payload: items,
});

export const removeItem = (item) => ({
  type: CartActionTypes.REMOVE_ITEM,
  payload: item,
});

export const clearItemFromCart = (item) => ({
  type: CartActionTypes.CLEAR_ITEM_FROM_CART,
  payload: item,
});

export const clearNormalPurchaseItems = (item) => ({
  type: CartActionTypes.CLEAR_NORMAL_PURCHASE_ITEMS,
  payload: item,
});

export const clearAllItemsFromCart = () => ({
  type: CartActionTypes.CLEAR_ALL_ITEMS_FROM_CART,
});
