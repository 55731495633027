import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@chakra-ui/react';
import { ReactComponent as ErrorIcon } from '../../assets/cloud-error.svg';
import CustomButton from '../../components/button/custom-button.component';
import './404.styles.scss';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Container className="errorpage">
      <div className="errorpage--content">
        <div className="error-icon">
          <ErrorIcon />
        </div>
        <div className="error-detail">
          <h1>404</h1>
          <h3>OOPS! PAGE NOT FOUND</h3>
          <p>
            Sorry but the page you are looking for does not exist, has been
            removed, had its name changed or is temporarily unavailable
          </p>

          <CustomButton variant="red" onClick={() => navigate('/')}>
            BACK TO HOME
          </CustomButton>
        </div>
      </div>
    </Container>
  );
};

export default NotFoundPage;
