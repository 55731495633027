import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import './policy.styles.scss';

const WarrantyPolicy = () => (
  <Box w="100%" className="policy-page">
    <Container maxW={1400}>
      <h1>Warranty</h1>
      <p>
        All EdgePi branded products carry a one-year warranty against defects in
        materials and workmanship. If a product proves defective in materials or
        workmanship within one year from the date of purchase, we will replace
        or repair it at our discretion. Product replacement is your exclusive
        remedy and our sole obligation for any breach of warranty, except that,
        if, after a reasonable number of attempts, we are unable to provide you
        a product that meets the above warranty, we will refund the purchase
        price for that product, as our sole obligation and your sole remedy.
        Products returned under warranty after 30 days may be replaced with
        refurbished or remanufactured goods.
      </p>
      <p>
        All other third-party products sold by EdgePi are covered by a 90-day
        repair or replacement warranty. This includes accessories such as power
        supplies, cables, sensors, and related items.
      </p>
      <hr />

      <p>
        EXCEPT AS EXPRESSLY PROVIDED IN THIS WARRANTY SECTION, WE MAKE NO
        REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT
        TO ANY PRODUCT, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF
        MERCHANTABILITY, NON-INFRINGEMENT OR FITNESS FOR ANY PARTICULAR PURPOSE,
        AND WE HEREBY DISCLAIM SAME. In no event will we be liable, whether in
        contract, tort or under any other legal theory, for lost profits or
        revenues, loss of use or similar economic loss, for any indirect,
        special, incidental, consequential, punitive or similar damages arising
        out of or in connection with any products (including non-conforming
        products), or for any third-party claims against you relating to the
        products, even if we have been advised of the possibility of such claim.
      </p>
      <p>
        In no event will our monetary liability (whether in contract, tort or
        under any other legal theory) in respect of any product exceed the
        purchase price that you paid to us for it. This warranty will be void if
        product labels or serial numbers are removed or defaced. This warranty
        does not apply to products that have been subject to abnormal use,
        abnormal conditions, improper storage, exposure to moisture or dampness,
        unauthorized modifications, unauthorized repair, misuse, neglect,
        accident, alteration, improper installation or wiring or other acts
        which are not our fault, including damage caused in shipping. The
        warranty does not apply to any product that has been damaged by external
        causes such as fire, flood, sand, dirt, lightning, exposure to weather,
        acts of God, battery leakage, theft, blown fuses, improper use of any
        electrical source or connection to products not recommended by us in
        writing.
      </p>
      <p>
        We do not assume any responsibility for your product design,
        installation or operation. Our products are not fault-tolerant and are
        not designed, manufactured or intended for use or resale as on-line
        control equipment in hazardous environments requiring fail-sale
        performance, such as in the operation of nuclear facilities, aircraft
        navigation or communication systems, air traffic control, direct life
        support machines, or weapons systems, in which the failure of the
        product could lead directly to death, personal injury or severe physical
        or environmental damage. Equipment damage or serious injury to personnel
        can result from the failure to follow all applicable electrical codes
        and safety standards.
      </p>
    </Container>
  </Box>
);

export default WarrantyPolicy;
