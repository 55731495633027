import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Container } from '@chakra-ui/react';
import { ReactComponent as ErrorIcon } from '../assets/cloud-error.svg';
import './error/error.styles.scss';

const ErrorHandler = () => {
  const navigate = useNavigate();

  return (
    <Box p={4} className="errorpage">
      <Container maxW="full" className="error-container">
        <div className="error-icon">
          <ErrorIcon />
        </div>

        <Flex
          className="error-detail"
          flexDirection="column"
          alignItems="center"
        >
          <h3>Something went wrong</h3>
          <p>
            The application encountered an unexpected error. Please click the
            button to go back to the homepage or contact us for support.
          </p>

          <Button
            mt={6}
            className="btn-group"
            bg="#DB2F5C"
            color="#FFF"
            variant="solid"
            w={200}
            onClick={() => {
              navigate('/');
              navigate(0);
            }}
            _hover={{ bg: '#db2f5d99' }}
          >
            Back to Homepage
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};

export default ErrorHandler;
