import { store } from '../../redux/store';
import { signOutUser } from '../../redux/user/user.actions';

const STORE_API_ENDPOINT = process.env.REACT_APP_STORE_API;
const createCheckoutSession = async (mode, currentUser, products, type) => {
  // Check if user information is available in DB. Only with guest checkout
  if (currentUser && !currentUser.isLoggedIn) {
    const options = {
      method: 'GET',
      contentType: 'application/json',
    };

    const checkGuestUser = await fetch(
      `${STORE_API_ENDPOINT}/guest-user-exists/${currentUser.userEmail}`,
      options
    );
    // if guest user not found, need to remove the current user in localstorage
    if (checkGuestUser.status !== 200) {
      store.dispatch(signOutUser());
    }
  }

  const stripeCheckoutURL =
    mode === 'payment_future'
      ? `${STORE_API_ENDPOINT}/predorder-checkout-session`
      : `${STORE_API_ENDPOINT}/create-checkout-session`;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      customerID: currentUser ? currentUser.stripeCustomerId : '',
      userEmail: currentUser ? currentUser.userEmail : '',
      customerCognitoID: currentUser ? currentUser.cognitoID : '',
      account_id: currentUser ? currentUser.accountId : '',
      cancelURL: window.location.href,
      type, // EdgePi or null
      ...products,
    }),
    redirect: 'follow',
  };

  const response = await fetch(stripeCheckoutURL, options);
  const json = await response.json();

  return { status: response.status, ...json };
};

export default createCheckoutSession;
