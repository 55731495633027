import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../redux/user/user.actions';
import storeFetchData from '../../api/storeFetchData';
import './account-migration.styles.scss';

const STORE_API_ENDPOINT = process.env.REACT_APP_STORE_API;

const AccountMigration = ({ setCurrentUser }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const retry = (fn, retriesLeft = 30, interval = 200) => {
    return new Promise((resolve, reject) => {
      fn()
        .then((response) => {
          if (response.status === 200) {
            // user is found (200)

            // Find the default account
            const defaultAccount = response.user.accounts.find(
              (account) => account.default
            );
            // Save the user information to Redux Store
            setCurrentUser({
              stripeCustomerId: defaultAccount.stripe_customer_id,
              userEmail: response.user.user_email,
              cognitoID: response.user.cognito_user_id,
              accounts: defaultAccount,
              accountId: defaultAccount.account_id,
              isLoggedIn: true,
            });
            resolve();
            navigate('/');
          } else {
            // user is not found (404) => also keep retry func running
            setTimeout(() => {
              if (retriesLeft === 1) {
                console.log('Cannot find user. Maximum retries exceeded');

                toast({
                  title: 'Failed to get your account information. ',
                  description:
                    'Please try again later, or contact us for support',
                  status: 'error',
                  position: 'top',
                  duration: 10000,
                  isClosable: true,
                });

                return;
              }
              retry(fn, retriesLeft - 1, interval).then(resolve, reject);
            }, interval);
          }
        })
        .catch((error) => {
          // failed to fetch data from api => retry
          setTimeout(() => {
            if (retriesLeft === 1) {
              // maximum retries exceeded
              reject(error);
              console.log(
                'Cannot fetch data from BE. Maximum retries exceeded'
              );
              console.error(error);

              toast({
                title: 'Failed to get your account information. ',
                description:
                  'Please try again later, or contact us for support',
                status: 'error',
                position: 'top',
                duration: 10000,
                isClosable: true,
              });

              return;
            }
            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
  };

  useEffect(() => {
    if (!location.state.allowMigration) {
      navigate('/');
    }
  }, []);
  const handleCreateAccount = async (migrateAccount) => {
    const obj = {
      method: 'POST',
      url: `${STORE_API_ENDPOINT}/users`,
      contentType: 'application/json',
      data: { migrate_account: migrateAccount },
    };
    const res = await storeFetchData(obj);

    if (res.response.success) {
      toast({
        title: migrateAccount
          ? 'Your account has been migrated'
          : 'Account Created Successful',
        status: 'success',
        description: 'You will be redirected to the Homepage shortly.',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });

      // After migration => Need to get user information and save it in Redux Store
      const getUserObj = {
        method: 'GET',
        url: `${STORE_API_ENDPOINT}/users/${location.state.cognitoEmail}`,
        contentType: 'application/json',
      };

      retry(() => storeFetchData(getUserObj));
    } else {
      toast({
        // BE error occur (5xx)
        title: 'Failed to migrate your account. Please contact us for support.',
        status: 'error',
        position: 'top',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={4} className="migration-page">
      <Container maxW={1400} className="migration-container">
        <h3>Before we log you in to your EdgePi Portal ...</h3>
        <p>
          We’ve noticed that the email you’ve used for your account creation is
          linked to the following guest account(s).
        </p>

        <TableContainer mt={10} mb={10} className="accounts-table">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Account ID</Th>
                <Th>Created On</Th>
              </Tr>
            </Thead>
            <Tbody>
              {location.state.accounts && location.state.accounts.length > 0
                ? location.state.accounts.map((account) => (
                    <Tr key={account.account_id}>
                      <Td>{account.stripe_email || ''}</Td>
                      <Td>{account.account_id || ''}</Td>
                      <Td>{account.created_on || ''}</Td>
                    </Tr>
                  ))
                : ''}
            </Tbody>
          </Table>
        </TableContainer>

        <h4>Would you like to merge these guest accounts.</h4>
        <p>
          Once merged, purchases and subscription orders from these guest
          accounts will be shown through your personal account.
        </p>

        <Flex justifyContent="center" flexWrap="wrap" mt={6}>
          <Button
            mt={6}
            mr={8}
            className="btn-group"
            bg="#3A3A3C"
            color="#FFF"
            variant="solid"
            w={250}
            onClick={() => {
              handleCreateAccount(false);
            }}
            _hover={{ bg: '#3A3A3C99' }}
          >
            Continue without merge
          </Button>
          <Button
            mt={6}
            mr={8}
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={250}
            onClick={() => {
              handleCreateAccount(true);
            }}
            _hover={{ bg: '#db2f5d99' }}
          >
            Merge Guest Accounts
          </Button>
        </Flex>
      </Container>
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(null, mapDispatchToProps)(AccountMigration);
