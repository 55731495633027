import generateAccessToken from './generateAccessToken';

const STORE_API_ENDPOINT = process.env.REACT_APP_STORE_API;

const checkOrderStatus = async (stripeSessionID, isLoggedIn, userEmail) => {
  const myHeaders = new Headers();
  if (isLoggedIn) {
    const token = await generateAccessToken();
    myHeaders.append('Authorization', `Bearer ${token}`);
  }

  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${STORE_API_ENDPOINT}/order-status/${stripeSessionID}?user_email=${userEmail}`,
    requestOptions
  );
  const json = await response.json();
  return { status: response.status, ...json };
};

export default checkOrderStatus;
