const API_ENDPOINT = process.env.REACT_APP_STORE_API;

const getProducts = async (path, options = {}) => {
  const response = await fetch(`${API_ENDPOINT}${path}`, options);
  const json = await response.json();

  if (!Array.isArray(json) && Object.keys(json).length === 0) {
    throw new Error('Empty Response');
  }

  return json;
};

export default getProducts;
