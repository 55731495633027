import React from 'react';
import './price.styles.scss';

const Price = ({ currency, amount, variant, interval }) => {
  let currencyFormat;
  switch (currency) {
    case 'cad':
      currencyFormat = 'CA$';
      break;
    case 'usd':
      currencyFormat = '$';
      break;
    default:
      currencyFormat = currency;
  }

  return (
    <>
      <div className="price-box">
        <h4 className={`${variant}`}>
          {currencyFormat}
          {(amount / 100).toFixed(2).replace(/\.00/, '')}
        </h4>
        <p>{interval ? `/ ${interval}` : ''}</p>
      </div>
    </>
  );
};

export default Price;
