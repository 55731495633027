import React from 'react';
import { connect } from 'react-redux';
import { clearItemFromCart } from '../../redux/cart/cart.actions';
import './cart-item.styles.scss';

const CartItem = ({ item, clearItem }) => (
  <div className="cart-item">
    <img src={item.images[0]} alt={item.name} />
    <div className="item-details">
      <div className="item-details--wrapper">
        <span className="name">{item.name}</span>
        <span className="price">
          {item.quantity} x{' $'}
          {item.prices.length === 2 && item.prices[0].nickname === 'sale'
            ? item.prices[0].unit_amount / 100
            : item.prices.length === 2 && item.prices[1].nickname === 'sale'
            ? item.prices[1].unit_amount / 100
            : item.prices.length === 1
            ? item.prices[0].unit_amount / 100
            : ''}
        </span>
      </div>
      <div className="remove-button" onClick={() => clearItem(item)}>
        &#10005;
      </div>
    </div>
  </div>
);

const mapDispatchToProps = (dispatch) => ({
  clearItem: (item) => dispatch(clearItemFromCart(item)),
});

export default connect(null, mapDispatchToProps)(CartItem);
