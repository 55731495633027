import generateAccessToken from './generateAccessToken';

const storeFetchData = async (obj) => {
  const { method, data, url, contentType } = obj;

  const token = await generateAccessToken();
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  if (contentType && contentType === 'application/json') {
    myHeaders.append('Content-Type', 'application/json');
  }

  const raw = JSON.stringify(data);

  const requestOptions = {
    method,
    headers: myHeaders,
    body: raw || null,
  };

  try {
    const response = await fetch(`${url}`, requestOptions);
    const json = await response.json();
    return { status: response.status, ...json };
  } catch (err) {
    return err;
  }
};

export default storeFetchData;
