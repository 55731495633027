import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';

export const CardBadge = (props) => {
  const { variant, children, ...flexProps } = props;
  return (
    <Flex
      className={`card-badge--${variant}`}
      justifyContent="center"
      alignItems="center"
      bg={useColorModeValue('#dc2f5c', '#bd214b')}
      py={2}
      {...flexProps}
    >
      <Text
        fontSize="10px"
        textTransform="uppercase"
        fontWeight="bold"
        letterSpacing="wider"
        color={useColorModeValue('white', 'gray.800')}
      >
        {children}
      </Text>
    </Flex>
  );
};
