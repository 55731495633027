import React from 'react';
import { Image } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import productPlaceholder from '../../assets/product_placeholder.jpeg';
import './image-slider.styles.scss';

const ImageSlider = ({ slides }) => (
  <Carousel
    infiniteLoop
    showThumbs={false}
    showArrows={false}
    showStatus={false}
  >
    {slides.map((slide, index) => {
      return (
        <Image
          key={index}
          src={slide ?? productPlaceholder}
          boxSize="400px"
          objectFit="contain"
          alt="EdgePi Product"
        />
      );
    })}
  </Carousel>
);

export default ImageSlider;
