import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container, Flex, Icon, Tooltip, useToast } from '@chakra-ui/react';
import {
  MdOutlineKeyboardBackspace,
  MdOutlineInfo,
  MdLockOutline,
} from 'react-icons/md';
import { signOutUser } from '../../../redux/user/user.actions';
import {
  selectCurrentUser,
  selectCurrentSession,
} from '../../../redux/user/user.selectors';
import { selectQuickbuyItem } from '../../../redux/quickbuy/quickbuy.selectors';
import { selectCartItems } from '../../../redux/cart/cart.selectors';
import { setCurrentError } from '../../../redux/error/error.actions';
import verifyRecaptcha from '../../../api/verifyRecaptcha';
import createCheckoutSession from '../../../api/stripe/createCheckoutSession';
import CustomButton from '../../../components/button/custom-button.component';
import './registered-user-shipping.styles.scss';

const RegisterUserShipping = ({
  currentUser,
  currentSession,
  stripeData,
  shippingCountry,
  editMode,
  quickbuyItem,
  cartItems,
  signOutUser,
  setCurrentError,
}) => {
  const [isLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleResetGuestAccount = () => {
    signOutUser();
    editMode(true);
  };

  const handleCheckoutItems = async () => {
    setButtonLoading(true);

    // Check if user has shipping information
    if (!stripeData.shipping) {
      toast({
        title:
          'Please provide your shipping information before continuing to checkout !',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      setButtonLoading(false);
      return false;
    }

    const recaptcha = await verifyRecaptcha();
    if (!recaptcha) {
      console.log('verifying reCaptcha ... Failed!');
      return false; // Stop executing checkout if failed reCaptcha check
    }
    console.log('verifying reCaptcha ... OK!');
    const buyingSessions = {};
    let checkoutType = ''; // setup type: go to plans after purchase if product is edgepi

    // for quickbuy item
    if (currentSession && currentSession === 'quickbuy') {
      const findSalePrice = quickbuyItem[0].prices.filter(
        (price) => price.nickname === 'sale'
      );
      if (
        quickbuyItem[0].metadata.Category &&
        quickbuyItem[0].metadata.Category === 'EdgePi'
      ) {
        checkoutType = 'EdgePi';
      } else {
        checkoutType = null;
      }

      const priceIDs = [];
      const temObj = {};
      temObj.price =
        findSalePrice.length > 0
          ? findSalePrice[0].id
          : quickbuyItem[0].prices[0].id;
      temObj.quantity = 1;

      priceIDs.push(temObj);

      buyingSessions.priceIDs = priceIDs;
      buyingSessions.product_id = quickbuyItem[0].id;
      buyingSessions.product_sku = quickbuyItem[0].metadata.SKU;
      buyingSessions.product_model = quickbuyItem[0].metadata.Model;
      buyingSessions.product_name = quickbuyItem[0].name;
      buyingSessions.product_price =
        findSalePrice.length > 0
          ? findSalePrice[0].unit_amount
          : quickbuyItem[0].prices[0].unit_amount;
      buyingSessions.checkoutMode = quickbuyItem[0].metadata['Checkout Mode'];
    } else {
      // for items in cart
      const tempPriceIDs = [];
      buyingSessions.mode = 'payment'; // Add 'one-time payment mode by default'

      cartItems
        .filter((cartItem) => cartItem.quantity)
        // .filter(
        //   (cartItem) => cartItem.metadata['Checkout Mode'] === 'payment_auth'
        // )
        .forEach((item) => {
          const itemObj = {};
          const salePrice = item.prices.find(
            (price) => price.nickname === 'sale'
          );
          itemObj.price = salePrice ? salePrice.id : item.prices[0].id;
          itemObj.quantity = item.quantity;
          tempPriceIDs.push(itemObj);
        });

      const isEdgePiInCart = cartItems.filter(
        (cartItem) => cartItem.metadata.Category === 'EdgePi'
      );

      if (isEdgePiInCart.length > 0) {
        checkoutType = 'EdgePi';
      } else {
        checkoutType = null;
      }
      buyingSessions.priceIDs = tempPriceIDs;
      buyingSessions.checkoutMode = 'payment_auth'; // cart only allows 'payment_auth' items
    }

    const stripeCheckout = await createCheckoutSession(
      buyingSessions.checkoutMode,
      currentUser,
      buyingSessions,
      checkoutType
    );

    if (
      stripeCheckout &&
      stripeCheckout.status === 200 &&
      stripeCheckout.redirect
    ) {
      setButtonLoading(false);
      window.location.href = stripeCheckout.redirect;
    } else {
      setButtonLoading(false);
      const err = {
        Status: stripeCheckout.status,
        Message: stripeCheckout.message,
      };
      setCurrentError(err);
      navigate('/error');
    }

    return null;
  };

  return (
    stripeData && (
      <Container maxW={800}>
        <div className="text-banner">
          <Icon
            className="back-btn"
            w={12}
            h={12}
            as={MdOutlineKeyboardBackspace}
            onClick={() => navigate(-1)}
          />
          <h2>
            Please review your Email and Shipping Address to proceed with the
            order.
          </h2>
        </div>

        <div className="contact-information">
          <h3>Contact Information</h3>

          <Flex justifyContent="space-between" flexWrap="wrap">
            <div>
              <h4>
                Contact Email Address{' '}
                <Tooltip
                  shouldWrapChildren
                  hasArrow
                  label="These are the details we will use to contact you. Your login details may be different."
                >
                  <Icon w={6} h={6} as={MdLockOutline} />
                </Tooltip>
              </h4>

              <p>{stripeData.email}</p>
            </div>

            <div>
              <h4>Phone Number</h4>
              <p>
                {stripeData.shipping ? stripeData.shipping.phone || 'N/A' : ''}
              </p>
            </div>
          </Flex>
        </div>

        <div className="shipping-information">
          <h3>
            Shipping Information{' '}
            <Tooltip
              shouldWrapChildren
              hasArrow
              label="Your shipping address is collected in order to calculate your shipping rates. We currently only ship to Canada, US, EU and Australia."
            >
              <Icon w={6} h={6} as={MdOutlineInfo} />
            </Tooltip>
          </h3>
          <h4>Shipping Address</h4>
          {stripeData.shipping && (
            <>
              <p>{stripeData.shipping.name}</p>
              <p>{stripeData.shipping.address.line1}</p>
              <p>{stripeData.shipping.address.line2 ?? ''}</p>
              <p>
                {`${stripeData.shipping.address.city}, ${
                  stripeData.shipping.address.state
                } ${
                  stripeData.shipping.address.postal_code
                    ? stripeData.shipping.address.postal_code
                    : ''
                }`}
              </p>
              <p>{shippingCountry}</p>
            </>
          )}
        </div>
        {currentUser && !currentUser.isLoggedIn && (
          <div className="reset-account" onClick={handleResetGuestAccount}>
            <p>Reset Guest Account</p>
          </div>
        )}

        <div className="form-btn-group">
          <CustomButton
            onClick={() => editMode(true)}
            variant="black"
            width={300}
          >
            Edit Details
          </CustomButton>
          <CustomButton
            onClick={handleCheckoutItems}
            variant="red"
            width={300}
            isLoading={isLoading}
          >
            Continue to Checkout
          </CustomButton>
        </div>
      </Container>
    )
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  currentSession: selectCurrentSession,
  quickbuyItem: selectQuickbuyItem,
  cartItems: selectCartItems,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentError: (error) => dispatch(setCurrentError(error)),
  signOutUser: () => dispatch(signOutUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterUserShipping);
