/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container, Flex } from '@chakra-ui/react';
import moment from 'moment';

const DeveloperPage = () => {
  const [latestVersionDate, setLatestVersionDate] = useState('');
  const [localVersionDate, setLocalVersionDate] = useState('');

  useEffect(() => {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        setLatestVersionDate(
          moment(meta.buildDate).format('dddd, MMMM Do YYYY, h:mm:ss a')
        );
        setLocalVersionDate(
          moment(parseInt(localStorage.getItem('buildDate'), 10)).format(
            'dddd, MMMM Do YYYY, h:mm:ss a'
          )
        );
      });
  }, []);
  return (
    <div className="static-page">
      <div className="static-page--content">
        <Container maxW={1400} mt={24}>
          <Flex justifyItems="center" alignItems="center">
            <div>
              <h3>App version : {process.env.REACT_APP_VERSION}</h3>
              <h3>Latest Build: {latestVersionDate}</h3>
              <h3>Local Build: {localVersionDate}</h3>
              <h3>Packages updated on : Aug 16, 2023</h3>
            </div>
          </Flex>
        </Container>
      </div>
    </div>
  );
};

export default DeveloperPage;
