import React from 'react';
import { Container, Box, Flex, Icon } from '@chakra-ui/react';
import { FaLinkedin, FaFacebookSquare } from 'react-icons/fa';
import { FaSquareXTwitter, FaSquareYoutube } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import './footer.styles.scss';

const Footer = () => (
  <Box w="100%" p={4} className="footer">
    <Container maxW={1400}>
      <Box>
        <Flex
          className="footer-credit"
          justify="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Flex flexDirection="column">
            <h3>CONTACT US</h3>
            <p>OSENSA Innovations Corp.</p>
            <p>8672 Commerce Ct.</p>
            <p>Burnaby, BC, Canada V5A 4N7</p>
            <a href="mailto:info@edgepi.com">info@edgepi.com</a>
          </Flex>

          <Flex flexDirection="column" className="social-media">
            <div className="social-media--container">
              <a
                href="https://twitter.com/edgepi_cloud"
                target="_blank"
                rel="noreferrer"
                aria-label="social-media-btn"
              >
                <Icon w={10} h={10} as={FaSquareXTwitter} />
              </a>
              <a
                href="https://www.linkedin.com/company/edgepi-cloud"
                target="_blank"
                rel="noreferrer"
                aria-label="social-media-btn"
              >
                <Icon w={10} h={10} as={FaLinkedin} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100088458851850"
                target="_blank"
                rel="noreferrer"
                aria-label="social-media-btn"
              >
                <Icon w={10} h={10} as={FaFacebookSquare} />
              </a>
              <a
                href="https://www.youtube.com/@edgepi"
                target="_blank"
                rel="noreferrer"
                aria-label="social-media-btn"
              >
                <Icon w={10} h={10} as={FaSquareYoutube} />
              </a>
            </div>

            <Box className="policy-pages-menu">
              <ul>
                <Link to="/warranty">
                  <li>Warranty</li>
                </Link>
                <Link to="/shipping-returns">
                  <li>Shipping & Returns</li>
                </Link>
                <Link to="/privacy-policy">
                  <li>Privacy Policy</li>
                </Link>
                <Link to="/terms-conditions">
                  <li>Terms & Conditions of Sales</li>
                </Link>
              </ul>
            </Box>
            <p>© {new Date().getFullYear()} OSENSA Innovations Corp.</p>
          </Flex>
        </Flex>
      </Box>
    </Container>
  </Box>
);

export default Footer;
