import checkAuthentication from '../checkAuthentication';

const STORE_API_ENDPOINT = process.env.REACT_APP_STORE_API;

const updateCustomerInfoFromStripe = async (
  stripeCustomerID,
  userEmail,
  accountID,
  values
) => {
  const user = await checkAuthentication();
  const myHeaders = new Headers();
  if (user) {
    const accessToken = user.signInUserSession.accessToken.jwtToken;
    myHeaders.append('Authorization', `Bearer ${accessToken}`);
  }
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    redirect: 'follow',
  };

  requestOptions.body = JSON.stringify(values);

  const response = await fetch(
    `${STORE_API_ENDPOINT}/customers/${stripeCustomerID}?user_email=${userEmail}&account_id=${accountID}`,
    requestOptions
  );
  const json = await response.json();
  return { status: response.status, ...json }; // Service Unavailable
};

export default updateCustomerInfoFromStripe;
