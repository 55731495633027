import { Button } from '@chakra-ui/react';
import * as React from 'react';

export const ActionButton = (props) => (
  <Button
    isDisabled={props.isDisabled}
    className={`${
      process.env.REACT_APP_SUBSCRIPTION_ACTIVE === 'false'
        ? 'disabled-btn'
        : ''
    } subscribe-btn ${props.isDisabled && `subscribe-btn-disabled`}`}
    w="100%"
    {...props}
  />
);
