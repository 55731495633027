import React, { useEffect } from 'react';
import './category-filter.styles.scss';

const CategoryFilter = ({
  activeCategory,
  setActiveCategory,
  setFiltered,
  categories,
}) => {
  useEffect(() => {
    if (activeCategory === 'All') {
      setFiltered(categories);
      return;
    }
    // Remove uncategorized products
    const updateCategories = categories.filter(
      (item) => item !== null && item !== undefined && item.trim() !== ''
    );
    const filtered = updateCategories.filter((product) =>
      product.includes(activeCategory)
    );
    setFiltered(filtered);
  }, [activeCategory]);

  return (
    <div className="filter-container">
      <div
        className={activeCategory === 'All' ? 'active' : ''}
        onClick={() => setActiveCategory('All')}
      >
        <h3>All</h3>
      </div>

      {categories.map((category) => (
        <div
          key={category}
          className={activeCategory === category ? 'active' : ''}
          onClick={() => setActiveCategory(category)}
        >
          <h3>{category}</h3>
        </div>
      ))}
    </div>
  );
};

export default CategoryFilter;
