import { useState } from 'react';
import data from '../data/allowed_countries.json';

const countries = data.data;
const useForm = (callback, initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const onChange = (input) => (event) => {
    setValues({ ...values, [input]: event.target.value });
  };

  const onSelectLocation = (value) => {
    const shippingCountryCode = countries.find(
      (country) => country.country === value[3]
    );
    setValues({
      ...values,
      shipping_address_line1: value[0],
      shipping_city: value[1],
      shipping_state: value[2],
      shipping_country: shippingCountryCode
        ? shippingCountryCode.code
        : value[3],
      shipping_postal_code: value[4],
    });
  };

  const onSubmit = (event) => {
    callback();
    event.preventDefault();
  };

  return {
    onChange,
    onSelectLocation,
    onSubmit,
    values,
  };
};
export default useForm;
