import generateAccessToken from './generateAccessToken';

const STORE_API_ENDPOINT = process.env.REACT_APP_STORE_API;

const checkPreOrderStatus = async (stripeSessionID, isLoggedIn) => {
  const myHeaders = new Headers();
  const url = `${STORE_API_ENDPOINT}/pre-order-status/${stripeSessionID}`;

  if (isLoggedIn) {
    const token = await generateAccessToken();
    myHeaders.append('Authorization', `Bearer ${token}`);
  }
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(url, requestOptions);
  const json = await response.json();
  return { status: response.status, ...json };
};

export default checkPreOrderStatus;
