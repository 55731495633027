/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import PricingPlans from '../../components/pricing-plans/PricingPlans';
import './plans.styles.scss';

const Plans = () => (
  <Box className="plans-page">
    {process.env.REACT_APP_SUBSCRIPTION_ACTIVE === 'false' && (
      <div className="text-banner">
        <h1>COMING SOON !</h1>
      </div>
    )}
    <Container maxW={1400}>
      <PricingPlans />
    </Container>
  </Box>
);

export default Plans;
