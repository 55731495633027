import React from 'react';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { loadStripe } from '@stripe/stripe-js';
import {
  Container,
  Image,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import CheckOutModal from '../../components/checkout-modal/checkout-modal.component';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import checkAuthentication from '../../api/checkAuthentication';
import verifyRecaptcha from '../../api/verifyRecaptcha';
import CheckoutItem from '../../components/checkout-item/checkout-item.component';
import CustomButton from '../../components/button/custom-button.component';
import {
  selectCartItems,
  selectCartTotal,
  selectCartItemsCount,
} from '../../redux/cart/cart.selectors';
import { setCurrentError } from '../../redux/error/error.actions';
import { setCurrentSession } from '../../redux/user/user.actions';
import { clearNormalPurchaseItems } from '../../redux/cart/cart.actions';
import './cart.styles.scss';

loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CartPage = ({ cartItems, total, itemCount, setCurrentSession }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const onCreateAccount = () => {
    Auth.federatedSignIn({
      customState: window.location.pathname,
    });
  };

  const errorNotify = (message) =>
    toast({
      title: message,
      status: 'error',
      position: 'top',
      duration: 2000,
      isClosable: true,
    });

  const navigate = useNavigate();

  const stripeCheckout = async () => {
    const recaptcha = await verifyRecaptcha();
    if (!recaptcha) {
      console.log('verifying reCaptcha ... Failed!');
      return false; // Stop execute checkout if failed reCaptcha check
    }
    console.log('verifying reCaptcha ... OK!');
    if (
      cartItems.length > 1 ||
      (cartItems.length === 1 && cartItems[0].quantity > 0)
    ) {
      setCurrentSession('cart');
      navigate('/shipping');
    } else {
      errorNotify('You do not have any products');
    }

    return null;
  };
  return (
    <VStack className="cart-page" columns={{ md: 1, lg: 2 }} spacing={12}>
      {cartItems.length === 0 ? (
        <div className="test-warning">
          <Image
            boxSize="200px"
            objectFit="contain"
            src="/empty-cart.png"
            alt="empty-cart"
          />
          <h2>YOUR CART IS EMPTY</h2>
          <p>Looks like you have not made your choice yet...</p>
        </div>
      ) : (
        <>
          <Container maxW={1400}>
            <div className="cart-header">
              <div className="header-product">
                <span>Product</span>
              </div>
              <div className="header-name">
                <span>Name</span>
              </div>
              <div className="header-quantity">
                <span>Quantity</span>
              </div>
              <div className="header-price">
                <span>Price</span>
              </div>
              <div className="header-subtotal">
                <span>Subtotal</span>
              </div>
            </div>

            {cartItems.length > 1 ||
            (cartItems.length === 1 && cartItems[0].quantity > 0) ? (
              cartItems
                .filter((cartItem) => cartItem.quantity)
                .map((cartItem) => (
                  <CheckoutItem key={cartItem.id} cartItem={cartItem} />
                ))
            ) : (
              <h2 className="test-warning">YOUR CART IS EMPTY</h2>
            )}

            <div className="total">
              {(itemCount >
                parseInt(process.env.REACT_APP_CART_MAX_QUANTITY, 10) ||
                total >
                  parseInt(process.env.REACT_APP_CART_MAX_AMOUNT, 10)) && (
                <h4>
                  Due to package size, weight restrictions, and insurance, we
                  cannot allow more than{' '}
                  {process.env.REACT_APP_CART_MAX_QUANTITY} items per order or
                  more than ${process.env.REACT_APP_CART_MAX_AMOUNT} in value.
                  You can break down your items into multiple orders. Please
                  contact us for high volume orders for better terms and
                  discounts.
                </h4>
              )}
              <VStack alignItems="flex-end" mb={4}>
                <h3>Total: ${total}</h3>

                <CustomButton
                  variant="red"
                  disabled={itemCount > 5 || total > 9999}
                  onClick={async () => {
                    const result = await checkAuthentication();
                    if (!result.username) {
                      onOpen();
                    } else {
                      stripeCheckout();
                    }
                  }}
                >
                  Continue to Checkout
                </CustomButton>
              </VStack>
            </div>

            <CheckOutModal
              isOpen={isOpen}
              onClose={onClose}
              onCreateAccount={onCreateAccount}
              onCheckOut={stripeCheckout}
            />
          </Container>
        </>
      )}
    </VStack>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  cartItems: selectCartItems,
  total: selectCartTotal,
  itemCount: selectCartItemsCount,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentError: (error) => dispatch(setCurrentError(error)),
  setCurrentSession: (mode) => dispatch(setCurrentSession(mode)),
  clearNormalPurchaseItems: () => dispatch(clearNormalPurchaseItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartPage);
