import { CartActionTypes } from './cart.types';
import { addItemToCart, addItemsToCart, removeItemToCart } from './cart.utils';

const INITIAL_STATE = {
  hidden: true,
  cartItems: [],
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartActionTypes.TOGGLE_CART_HIDDEN:
      return {
        ...state,
        hidden: !state.hidden, // => payload is not necessary in cart.action.js
      };
    case CartActionTypes.ADD_ITEM:
      return {
        ...state,
        cartItems: addItemToCart(state.cartItems, action.payload),
      };

    case CartActionTypes.ADD_ITEMS:
      return {
        ...state,
        cartItems: addItemsToCart(state.cartItems, action.payload),
      };

    case CartActionTypes.REMOVE_ITEM:
      return {
        ...state,
        cartItems: removeItemToCart(state.cartItems, action.payload),
      };

    case CartActionTypes.CLEAR_ITEM_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (cartItem) => cartItem.id !== action.payload.id
        ),
      };

    case CartActionTypes.CLEAR_NORMAL_PURCHASE_ITEMS:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (cartItem) => cartItem.metadata['Checkout Mode'] !== 'payment_auth'
        ),
      };

    case CartActionTypes.CLEAR_ALL_ITEMS_FROM_CART:
      // eslint-disable-next-line no-return-assign
      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
};
export default cartReducer;
