import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Image, Flex } from '@chakra-ui/react';
import CustomButton from '../../../components/button/custom-button.component';
import '../homepage.styles.scss';

const OriginalPage = () => {
  const navigate = useNavigate();
  return (
    <Box w="100%" className="homepage">
      <div className="homepage-hero">
        <Container maxW={1400} marginTop={12}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <div className="homepage-hero--text">
              <h1>
                Welcome to the <span>EdgePi Store</span> .
              </h1>
              <h3>
                Explore our variety of EdgePis and Data Plans to find one that
                perfectly suit your needs.
              </h3>
              <h3>The journey to endless possibilities begins here ...</h3>

              <CustomButton variant="red" onClick={() => navigate('/shop')}>
                Shop Now
              </CustomButton>
            </div>
            <Image
              boxSize="500px"
              objectFit="contain"
              src="/edgepi-hp-img.png"
              alt="EdgePi Product"
              p={4}
              mt={3}
              mb={3}
            />
          </Flex>
        </Container>
      </div>
      <div className="homepage-body" />
    </Box>
  );
};

export default OriginalPage;
