const API_ENDPOINT = process.env.REACT_APP_STORE_API;

const subscriptionCheckOut = async (currentUser, products) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      customerID: currentUser ? currentUser.stripeCustomerId : '',
      userEmail: currentUser ? currentUser.userEmail : '',
      customerCognitoID: currentUser ? currentUser.cognitoID : '',
      account_id: currentUser ? currentUser.accountId : '',
      callbackURL:
        window.location.pathname === '/checkout-process'
          ? '/checkout-process'
          : '',
      cancelURL: window.location.href,
      ...products,
    }),
    redirect: 'follow',
  };

  const response = await fetch(
    `${API_ENDPOINT}/create-subscription-checkout-session`,
    options
  );
  const json = await response.json();
  if (json.redirect) {
    window.location.href = json.redirect;
  } else {
    const err = { Status: json.status, ...json };
    console.log(err);
  }
};

export default subscriptionCheckOut;
