import { QuickbuyActionTypes } from './quickbuy.types';
import { addItemToQuickbuy } from './quickbuy.utils';

const INITIAL_STATE = {
  quickbuyItem: [],
};

const quickbuyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QuickbuyActionTypes.ADD_QUICKBUY_ITEM:
      return {
        ...state,
        quickbuyItem: addItemToQuickbuy(state.quickbuyItem, action.payload),
      };

    case QuickbuyActionTypes.REMOVE_QUICKBUY_ITEM:
      return {
        ...state,
        quickbuyItem: [],
      };

    default:
      return state;
  }
};
export default quickbuyReducer;
