const API_ENDPOINT = process.env.REACT_APP_STORE_API;

const verifyRecaptcha = async () => {
  console.log('verifying reCaptcha...');
  const token = await window.grecaptcha.execute(
    process.env.REACT_APP_RECAPTCHA_KEY,
    { action: 'submit' }
  );
  // call a backend API to verify reCAPTCHA response
  const response = await fetch(`${API_ENDPOINT}/verify-recaptcha`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'g-recaptcha-response': token,
    }),
  });
  const json = await response.json();
  return json.success ?? false;
};

export default verifyRecaptcha;
