import { Auth } from 'aws-amplify';

const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API_ENDPOINT;

const useAuthentication = () => {
  Auth.configure({
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,

    // Note: enable cookieStore will make AWS Amplify Hosted UI to use httpOnly cookie to store the token instead of localStorage. This maybe helpful for security but it could make the cookies' size exceed the limit (8KB). This may cause an issue with request header.

    // Issue: https://github.com/osensa/edgepi-store-frontend/issues/392

    // cookieStorage: {
    //   domain: process.env.REACT_APP_COOKIE_DOMAIN,
    //   path: '/',
    //   expires: 0.5,
    //   secure: true,
    // },

    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: [
        'email',
        'openid',
        `${PORTAL_API_ENDPOINT}/event.read`,
        `${PORTAL_API_ENDPOINT}/order.read`,
        `${PORTAL_API_ENDPOINT}/user.read`,
        `${PORTAL_API_ENDPOINT}/user.update`,
        `${PORTAL_API_ENDPOINT}/user.create`,
        `${PORTAL_API_ENDPOINT}/customer.read`,
        `${PORTAL_API_ENDPOINT}/customer.update`,
        `${PORTAL_API_ENDPOINT}/customer.create`,
        `${PORTAL_API_ENDPOINT}/guest-user.read`,
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
      responseType: 'code',
    },
  });
};

export default useAuthentication;
