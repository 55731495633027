export const addItemToQuickbuy = (quickbuyItem, quickbuyItemToAdd) => {
  const existingQuickbuyItem = quickbuyItem.find(
    (quickbuyItem) => quickbuyItem.id === quickbuyItemToAdd.id
  );
  if (existingQuickbuyItem) {
    return quickbuyItem.map((quickbuyItem) =>
      quickbuyItem.id === quickbuyItemToAdd.id
        ? {
            ...quickbuyItem,
            quantity: 1,
          }
        : quickbuyItem
    );
  }

  return [
    ...quickbuyItem,
    { ...quickbuyItemToAdd, quantity: quickbuyItemToAdd.quantity },
  ];
};
