const STORE_API_ENDPOINT = process.env.REACT_APP_STORE_API;

const cancelPreorder = async (stripeSessionID, userEmail) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify({
      status: 'cancelled',
    }),
  };

  const response = await fetch(
    `${STORE_API_ENDPOINT}/preorder/status/${stripeSessionID}?user_email=${userEmail}`,
    requestOptions
  );
  const json = await response.json();
  return { status: response.status, ...json };
};

export default cancelPreorder;
