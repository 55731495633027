import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import './policy.styles.scss';

const Terms = () => (
  <Box w="100%" className="policy-page">
    <Container maxW={1400}>
      <h1>
        General Terms And Conditions Of Business With OSENSA Innovations Corp.
        (&quot;Company&quot;)
      </h1>
      <h2>1. ACCEPTANCE:</h2>
      <p>
        Acceptance by Customer of this Order by delivery, acknowledgement,
        acceptance or otherwise shall be unqualified, unconditional, and subject
        to and expressly limited to the terms and conditions set forth herein
        and no others. Notwithstanding anything to the contrary herein, if
        software is delivered hereunder, the terms and conditions of the
        software license attached thereto shall govern the licensing thereof.
      </p>
      <h2>2. RISK OF LOSS, DELIVERY AND PASSING OF TITLE:</h2>
      <p>
        Unless otherwise specified on the shipping documents, delivery shall be
        made EXW Company warehouse, Burnaby, British Columbia Canada, (Incoterms
        2000) which constitutes delivery when the goods are placed at the
        disposal of the buyer at the above named place (hereinafter
        &quot;Delivery&quot;) hereunder. Customer shall assume risk of loss upon
        Delivery as set forth above. Customer hereby grants Company a security
        interest in the Product and in any proceeds (including accounts
        receivable) thereof as security for all of its obligations hereunder,
        which security interest shall commence upon the Delivery of the Product
        and terminate upon full payment thereof. Upon request of Company,
        Customer shall execute any instrument or document required to perfect
        the security interest.
      </p>

      <h2>3. RIGHT OF INSPECTION/RETURN OF GOODS:</h2>
      <p>
        All goods and services provided for herein may be inspected by Customer
        upon delivery and Customer may reject defective articles or articles not
        conforming to descriptions furnished to Company within a reasonable time
        after delivery. At its option Customer may accept those goods which are
        not so defective and do so conform and may retain rejected articles at
        Customer&apos;s option. Customer shall not return any Product to Company
        without first obtaining written authorization (RMA) and instructions
        from Company. Except as expressly set forth in this Agreement or in
        writing from Company, Customer shall be responsible for all costs of
        packing and shipping any returned Product. Company in its discretion may
        charge Customer a restocking fee of 25% of the purchase price of any
        returned Product.
      </p>
      <h2>4. PRICING:</h2>
      <p>
        Pricing is inclusive of packaging only. Pricing does not include
        insurance, handling, freight, and all taxes and charges, whether similar
        or dissimilar, unless otherwise indicated on the face of this purchase
        order.
      </p>

      <h2>5. WARRANTIES/DISCLAIMERS/INDEMNIFICATION:</h2>
      <p>
        (i) Company represents and warrants to Customer that each Product shall
        be free from defects in workmanship and materials for a period of twelve
        (12) months after delivery to Customer. This Product warranty shall not
        apply to any Product that has been abused, damaged, altered or misused
        or that is defective as a result of causes external to the Product and
        not caused by Company. Company warrants the Product conforms to the
        written description of order provided by Customer (if applicable).
        Company further warrants that the Product is free and clear of all liens
        and encumbrances, and that, to the best of Company&apos;s knowledge, the
        Product and sale to Customer are in compliance with all applicable
        federal and provincial laws, rules and regulations. EXCEPT AS SET FORTH
        ABOVE, COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING
        BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
        FITNESS FOR A PARTICULAR PURPOSE. (ii) Company may rely on the accuracy
        and lawfulness of all information and material furnished by Customer to
        Company regarding the goods or services sold. Customer shall indemnify
        and hold harmless Company against any liabilities or damages, costs and
        expenses, (including reasonable attorneys fees) incurred in defending
        against any action arising out of Customer supplied information or
        material. (iii) This warranty and the limitations of liability and
        remedy allocate the risk of product failure between both parties.
        Company&apos;s pricing reflects these allocations of risk. The agents
        employees distributors and dealers of Company are not authorized to make
        modifications to this warranty or additional warranties binding on
        Company. Accordingly, additional statements such as advertising or
        representations, whether oral or written, do not constitute warranties
        by Company and should not be relied upon.
      </p>

      <h2>6. INVOICES/PAYMENT:</h2>
      <p>
        The time for terms of payment of Company&apos;s invoices shall commence
        with date of delivery of items shipped EXW Company warehouse, Coquitlam,
        British Columbia, (Incoterms 2000). If any amounts due hereunder are
        referred to an agent or attorney for collection, Customer shall be
        responsible to pay any costs (including reasonable attorney&apos;s fees)
        in connection with such collection effort. Failure to pay any amounts
        due to Company within fifteen (15) days after the specified due date
        will result in a late charge of one hundred dollars ($100.00) promptly
        upon invoice. In addition, any amounts past due shall bear simple
        interest from and after the due date until payment is made at a rate of
        18% per annum, or if not allowable, to the fullest extent allowed by the
        law of the applicable province or territory.
      </p>

      <h2>7. DAMAGES:</h2>
      <p>
        IN NO EVENT SHALL COMPANY BE LIABLE FOR, INCIDENTAL, SPECIAL,
        CONSEQUENTIAL OR PUNITIVE DAMAGES IN CONNECTION WITH THE PERFORMANCE OR
        FAILURE TO PERFORM PURSUANT TO THIS AGREEMENT. IN NO EVENT SHALL
        COMPANY&apos;S LIABILITY FOR DAMAGES FOR ANY REASON EXCEED AN AMOUNT
        EQUAL TO THE TOTAL OF PAYMENTS MADE BY CUSTOMER TO COMPANY FOR THE
        PARTICULAR CLAIM FROM WHICH THE CAUSE OF ACTION OCCURRED.
      </p>

      <h2>8. RIGHTS AND RESERVATIONS:</h2>
      <p>
        (i) Customer agrees that the information contained in any devices,
        processes, or other records or oral descriptions (all hereinafter called
        descriptive materials) which are furnished to Customer by Company and
        relating to this order is the proprietary property of Company or its
        supplier and such information shall only be used for the purpose of this
        order. Without written approval from Company, Customer shall not, except
        as necessary for the performance of this order, duplicate such
        descriptive materials or disclose any such information to third parties.
        (ii) Customer shall not remove, hide or modify any marks of Company on
        Products, documentation, packaging or other materials. (iii) Upon the
        termination of this Agreement, Customer shall cease any use of the marks
        of Company. Customer shall not engage in or allow the licensing, use,
        leasing, loaning, translation, reproduction, copying or modification of
        any Product, mark of Company, or documentation of Company, except to the
        extent authorized by this Agreement. Customer shall not operate any
        business or Internet domain name using, market any product of Customer
        with or seek to register in any jurisdiction, the market or name of
        Company, or a confusingly similar market of name, without the prior
        written consent of Company. Customer has no right to obtain the source
        code of any software Product. Customer shall not engage in or allow
        reverse engineering, reverse compiling or copying of the &quot;look and
        feel&quot; of any product.
      </p>

      <h2>9. CHANGES:</h2>
      <p>
        Customer shall have the right to make changes from time to time in the
        work to be performed and/or goods to be furnished under this contract,
        subject to acceptance by Company. If such changes cause an increase or
        decrease in the amount due under the contract or in the time required
        for its performance, an equitable adjustment shall be made. Only those
        written changes incorporated in a copy of a form duly marked
        &apos;Change Order&apos; or similar terms issued by Company shall be
        valid; only those oral changes authorized by representatives of Company
        shall be valid and they will be confirmed by a written &apos;Change
        Order&apos;.
      </p>

      <h2>10. FORCE MAJEURE:</h2>
      <p>
        Company shall not be liable for, and is excused from, any failure to
        deliver or perform or delay in delivery or performance due to causes
        beyond its reasonable control including, but not limited to terrorism,
        acts of nature, government, fire, labour difficulty, transportation
        problems, interruptions of power or communication, or natural disasters.
      </p>

      <h2>11. USE OF PRODUCT:</h2>
      <p>
        Customer shall use Products only in compliance with all applicable
        instructions and warnings and safety practices, procedures and
        precautions supplied by Company.
      </p>

      <h2>12. MISCELLANEOUS:</h2>
      <p>
        (a) NOTICES: All notices shall be in writing and deemed given when
        delivered in person, by overnight delivery through a commercial courier
        service, or by certified mail, return receipt requested. Notices shall
        be addressed to each party at its address or such other address as the
        recipient may have specified by earlier notice to the sender.
      </p>
      <p>
        (b) ASSIGNMENT; SUCCESSORS: This Agreement shall not be assigned by
        Customer. This Agreement shall be binding upon and inure to the benefit
        of the parties, their successors and permitted assigns.
        <p>
          (c) ENTIRE AGREEMENT: This Agreement constitutes the entire Agreement
          between the parties with respect to its subject matter; all prior
          agreements, representations, statements, negotiations and undertakings
          with respect to such subject matter are terminated and superseded
          hereby.
        </p>
        <p>
          (d) AMENDMENTS: No amendment to this Agreement shall be effective
          unless it is in writing and signed by a duly authorized representative
          of each party. The term &quot;Agreement&quot; or &quot;Order&quot;, as
          used herein, includes all exhibits and attachments hereto and any
          future written amendments, modifications, or supplements made in
          accordance herewith.
        </p>
        <p>
          (e) SURVIVAL: After expiration or termination of this Agreement, all
          provisions relating to payment shall survive until completion of
          required payments. Furthermore, the expiration or termination of this
          agreement shall not affect provisions of same which by their terms and
          meaning are of a continuing nature.
        </p>
        <p>
          (f) CONSENT TO BREACH NOT WAIVER: No term or provision hereof shall be
          deemed waived and no breach excused, unless such waiver or consent
          shall be in writing and signed by the party claimed to have waived or
          consented. Any consent by any party to, or waiver of, a breach by the
          other, whether express or implied, shall not constitute consent to,
          waiver of, or excuse for any other different or subsequent breach.
        </p>
        <p>
          (g) SEVERABILITY; ENFORCEABILITY: In the event any provisions of this
          Agreement are held illegal, void or unenforceable, the balance shall
          remain in effect. Company&apos;s failure to enforce a right hereunder
          promptly shall not be deemed a waiver of such right, and no waiver of
          a right under a provision shall constitute a waiver of any other right
          under such provision or any other provision.
        </p>
        <p>
          (h) GOVERNING LAW: This Agreement shall be deemed to have been made in
          the province of British Columbia, and shall be governed by and
          construed in accordance with the laws of the province of British
          Columbia, exclusive of its rules governing choice of law and conflicts
          of law.
        </p>
      </p>
    </Container>
  </Box>
);

export default Terms;
