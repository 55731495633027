/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Button,
  Container,
  Image,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { FaUserAlt } from 'react-icons/fa';
import './header.styles.scss';
import CartIcon from '../cart-icon/cart-icon.component';
import CartDropDown from '../cart-dropdown/cart-dropdown.component';
import useAuthentication from '../../api/useAuthentication';
import { selectCartHidden } from '../../redux/cart/cart.selectors';
import { signOutUser } from '../../redux/user/user.actions';

const MenuItems = ({ user, handleCloseMenu, screen, signOutUser }) => {
  const location = useLocation();
  const currentSlug = location.pathname;
  return (
    <>
      <Link
        className={`option ${
          currentSlug === '/shop' ? 'current-active-menu' : ''
        }`}
        to="/shop"
        onClick={() => handleCloseMenu()}
      >
        SHOP
      </Link>
      <Link
        className={`option ${
          currentSlug === '/plans' ? 'current-active-menu' : ''
        }`}
        to="/plans"
        onClick={() => handleCloseMenu()}
      >
        PLANS
      </Link>
      {screen !== 'mobile' && <CartIcon />}
      {screen !== 'mobile' ? (
        <Menu>
          <MenuButton
            as={Button}
            className={`user-btn ${user && 'logged-state'}`}
            mr={2}
          >
            <Icon color="#000" as={FaUserAlt} w={6} h={6} />
          </MenuButton>
          <MenuList>
            {user ? (
              <div className="login-menu">
                <h2>Hello ,</h2>
                <h3>{user}</h3>
                <div
                  className="sign-out"
                  onClick={() => {
                    signOutUser();
                    Auth.signOut();
                  }}
                >
                  Sign Out
                </div>
              </div>
            ) : (
              <MenuItem
                onClick={() => {
                  Auth.federatedSignIn({
                    customState: window.location.pathname,
                  });
                }}
              >
                Login
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      ) : user ? (
        <h3
          className="option"
          onClick={() => {
            signOutUser();
            Auth.signOut();
          }}
        >
          SIGN OUT
        </h3>
      ) : (
        <h3
          className="option"
          onClick={() => {
            Auth.federatedSignIn({
              customState: window.location.pathname,
            });
          }}
        >
          LOGIN
        </h3>
      )}

      <a className="external-link" href="https://edgepi.com" rel="noreferrer">
        <p>edgepi.com</p>
      </a>
    </>
  );
};
const Header = ({ hidden, signOutUser }) => {
  const [user, setUser] = useState();
  const [mobileMenu, setMobileMenu] = useState(false);
  useEffect(() => {
    const SetAuthUser = async () => {
      useAuthentication();
      const user = await Auth.currentAuthenticatedUser();
      if (user.username) {
        setUser(user.signInUserSession.idToken.payload.email);
      }
    };
    SetAuthUser();
  }, []);

  const handleMobibleMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  const handleCloseMenu = () => {
    setMobileMenu(false);
  };
  return (
    <div className="header">
      <div className="main-header">
        <Container className="main-header--wrapper" maxW="full">
          <Link className="logo-container" to="/">
            <Image src="/edgepi-store-logo.png" alt="edgepi-store" />
          </Link>
          <div className={`hamburger-icon ${mobileMenu ? 'nav-active' : ''}`}>
            <CartIcon />
            <div
              role="presentation"
              className="nav-icon"
              onClick={() => handleMobibleMenu()}
            >
              <div />
            </div>
          </div>
          <div className="options">
            <MenuItems
              user={user}
              signOutUser={signOutUser}
              handleCloseMenu={handleCloseMenu}
            />
          </div>

          {hidden ? null : <CartDropDown />}
        </Container>
        <Container
          className={`mobile-header ${
            mobileMenu ? 'mobile-header--active' : ''
          }`}
          maxW={1400}
        >
          <div className="mobile-header--options">
            <MenuItems
              screen="mobile"
              signOutUser={signOutUser}
              user={user}
              handleCloseMenu={handleCloseMenu}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signOutUser: () => dispatch(signOutUser()),
});

const mapStateToProps = createStructuredSelector({
  hidden: selectCartHidden,
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
