import React from 'react';
import { Spinner } from '@chakra-ui/react';
import './custom-button.styles.scss';

const CustomButton = ({
  children,
  variant,
  isLoading,
  disabled,
  ...otherProps
}) => (
  <div className={`${disabled ? 'custom-disabled' : ''}`}>
    <button
      type="button"
      className={`custom-button ${variant}-btn ${
        disabled ? 'custom-disabled' : ''
      }`}
      {...otherProps}
    >
      {isLoading ? <Spinner size="sm" color="#FFF" /> : children}
    </button>
  </div>
);

export default CustomButton;
