import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { setCurrentError } from '../../redux/error/error.actions';
import { selectQuickbuyItem } from '../../redux/quickbuy/quickbuy.selectors';
import { signOutUser } from '../../redux/user/user.actions';
import { selectCartItems } from '../../redux/cart/cart.selectors';
import getCustomerInfoFromStripe from '../../api/stripe/getCustomerInfoFromStripe';
import RegisterUserShipping from './registered-user-shipping/registered-user-shipping.component';
import ShippingEditComp from './shipping-edit/shipping-edit.component';
import CloudAnimation from '../../components/animation/cloud';
import data from '../../data/allowed_countries.json';
import CustomButton from '../../components/button/custom-button.component';
import './shipping.styles.scss';

const countries = data.data;

const ShippingPage = ({
  currentUser,
  quickbuyItem,
  cartItems,
  setCurrentError,
  signOutUser,
}) => {
  const [stripeData, setStripeData] = useState();
  const [shippingCountry, setShippingCountry] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [emptyCartMessage, setEmptyCartMessage] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const navigate = useNavigate();
  const handleEditMode = (bool) => {
    setEditMode(bool);
  };
  useEffect(() => {
    if (quickbuyItem.length === 0 && cartItems.length === 0) {
      setPageLoading(false);
      setEmptyCartMessage(true);
    }

    if (currentUser && currentUser.stripeCustomerId) {
      // Get customer info from Stripe
      const getCustomerInfo = async () => {
        const response = await getCustomerInfoFromStripe(
          currentUser.stripeCustomerId,
          currentUser.userEmail,
          currentUser.accountId
        );
        if (response.status === 200) {
          // check if logged user or // returning guest user

          setStripeData(response.response);

          if (response.response.shipping) {
            const countryName = countries.find(
              (item) => item.code === response.response.shipping.address.country
            );
            if (countryName) {
              setShippingCountry(countryName.country);
            }
          }
        } else if (response.status === 500) {
          // Error: Cannot get Stripe customer (500). This happens after account migration
          console.log(`Error: ${response.status}. Clear localStorage`);
          signOutUser(); // Clean up user information in localStorage
          setEditMode(true); // Go to Shipping Edit page
          setPageLoading(false);
        } else {
          const err = {
            Status: response.status,
            Message: response.message,
          };
          console.log(`Error: ${response.status}. Clear localStorage`);
          signOutUser();
          setCurrentError(err);
          navigate('/error');
        }
        setPageLoading(false);
      };
      getCustomerInfo();
    } else {
      // user with currentUser is null => treat as new user => go to Edit page
      setEditMode(true);
      setPageLoading(false);
    }
  }, [editMode]);

  return (
    <Box className="shipping-page">
      <Container maxW={1400} p={0}>
        <div className="text-banner">
          {emptyCartMessage && !pageLoading ? (
            <div className="empty-cart-message">
              <h3>You don&apos;t have any items in the cart</h3>
              <div className="status-page-button-group">
                <CustomButton variant="red" onClick={() => navigate('/')}>
                  BACK TO HOME
                </CustomButton>
              </div>
            </div>
          ) : editMode ? (
            <ShippingEditComp
              stripeData={stripeData}
              editMode={handleEditMode}
            />
          ) : (
            <RegisterUserShipping
              stripeData={stripeData}
              shippingCountry={shippingCountry}
              editMode={handleEditMode}
            />
          )}
        </div>
        {pageLoading && (
          <Box className="loading-container">
            <CloudAnimation />
          </Box>
        )}
      </Container>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  quickbuyItem: selectQuickbuyItem,
  cartItems: selectCartItems,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentError: (error) => dispatch(setCurrentError(error)),
  signOutUser: () => dispatch(signOutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingPage);
