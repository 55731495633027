import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from '@chakra-ui/react';
import { BsCartFill } from 'react-icons/bs';
import { selectCartItemsCount } from '../../redux/cart/cart.selectors';
// import { ReactComponent as ShoppingBagIcon } from '../../assets/shopping-bag.svg';
import './cart-icon.styles.scss';

const CartIcon = ({ itemCount }) => {
  const location = useLocation();
  const currentSlug = location.pathname;
  return (
    <Link to="/cart">
      <div className="cart-icon">
        <Icon
          className="shopping-bag-icon"
          color={currentSlug === '/cart' && '#dc2f5c'}
          as={BsCartFill}
          w={6}
          h={6}
        />
        <span className="item-count">{itemCount}</span>
      </div>
    </Link>
  );
};

const mapStateToProps = createStructuredSelector({
  itemCount: selectCartItemsCount,
});

export default connect(mapStateToProps, null)(CartIcon);
