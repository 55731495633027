import React, { Component } from 'react';
import ErrorHandler from './ErrorHandler';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state to show the error UI
    return { hasError: true };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    // Perform any additional error handling here, such as logging the error
  }

  render() {
    if (this.state.hasError) {
      // Render the custom error page
      return <ErrorHandler />;
    }

    // Render the children components normally
    return this.props.children;
  }
}

export default ErrorBoundary;
