import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tag, TagLabel } from '@chakra-ui/react';
import {
  clearItemFromCart,
  addItem,
  removeItem,
} from '../../redux/cart/cart.actions';
import Price from '../price/price.component';
import productPlaceholder from '../../assets/product_placeholder.jpeg';
import './checkout-item.styles.scss';

const CheckoutItem = ({ cartItem, addItem, removeItem, clearItem, mode }) => {
  const [checkoutPrice, setCheckoutPrice] = useState('');
  const { name, images, prices, quantity } = cartItem;
  useEffect(() => {
    const salePrice = prices.find((price) => price.nickname === 'sale');
    if (salePrice) {
      setCheckoutPrice(salePrice);
    } else {
      setCheckoutPrice(prices[0]);
    }
  }, []);

  return (
    <div className="checkout-item">
      <div className="image-container">
        {mode === 'payment_future' && (
          <Tag
            className="pre-order-tag"
            size="sm"
            borderRadius="full"
            variant="solid"
            colorScheme="green"
            ml={2}
          >
            <TagLabel>Pre Order</TagLabel>
          </Tag>
        )}
        <img src={images[0] ?? productPlaceholder} alt={name} />
      </div>
      <span className="name">
        <div className="product-name">
          <p>{name}</p>
          <span className="mobile-quantity">
            <div
              className={`${cartItem.quantity === 1 ? 'disable' : ''} arrow`}
              onClick={() => removeItem(cartItem)}
            >
              &#9664;
            </div>
            <span className="value">{quantity}</span>
            <div
              className={`${
                cartItem.quantity ===
                parseInt(process.env.REACT_APP_CART_MAX_QUANTITY, 10)
                  ? 'disable'
                  : ''
              } arrow`}
              onClick={() => addItem(cartItem)}
            >
              &#9654;
            </div>
          </span>
        </div>
        <div className="remove-button" onClick={() => clearItem(cartItem)}>
          Remove
        </div>
      </span>
      <span className="quantity">
        <div
          className={`${cartItem.quantity === 1 ? 'disable' : ''} arrow`}
          onClick={() => removeItem(cartItem)}
        >
          &#9664;
        </div>
        <span className="value">{quantity}</span>
        <div
          className={`${
            cartItem.quantity ===
            parseInt(process.env.REACT_APP_CART_MAX_QUANTITY, 10)
              ? 'disable'
              : ''
          } arrow`}
          onClick={() => addItem(cartItem)}
        >
          &#9654;
        </div>
      </span>
      <span className="price">
        {checkoutPrice && (
          <Price
            currency={checkoutPrice.currency}
            amount={checkoutPrice.unit_amount}
          />
        )}
      </span>

      <span className="subtotal">
        {checkoutPrice && (
          <Price
            currency={checkoutPrice.currency}
            amount={checkoutPrice.unit_amount * quantity}
          />
        )}
      </span>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  clearItem: (item) => dispatch(clearItemFromCart(item)),
  addItem: (item) => dispatch(addItem(item)),
  removeItem: (item) => dispatch(removeItem(item)),
});
export default connect(null, mapDispatchToProps)(CheckoutItem);
